import PropTypes from 'prop-types';
import { useForm, useWatch, FormProvider } from 'react-hook-form';
import FormRow from '@/components/core/form/FormRow';
import { useNodeById, useNodes } from '@/stores/analysisStore';
import { useEffect } from 'react';
import Select from '@/components/core/form/Select';

import debounce from 'debounce';
import { useCostOverridesByNode } from '@/stores/caseStore';
import LevelizationForm from '@/components/core/LevelizationForm';

const TEAPlotForm = ({ defaultValues, onSubmit, plotType = 'tea' }) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, control, register, reset } = methods;
  const nodes = useNodes();
  const nodeOptions = nodes.map(({ id, label }) => ({ label, value: id }));
  const debouncedSave = debounce(() => handleSubmit(onSubmit)(), 10);
  const isTeaPlot = plotType === 'tea';

  const [nodeId, levelizeBy] = useWatch({
    control,
    name: ['nodeId', 'levelizeBy'],
    defaultValue: defaultValues,
  });

  const node = useNodeById(nodeId);
  const costOverride = useCostOverridesByNode(nodeId);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <FormProvider {...methods}>
      <div className="group node">
        <form onChange={debouncedSave}>
          <div className="mt-2 grid grid-cols-2 gap-1">
            <FormRow label="Module" name="nodeId">
              <Select className="w-full truncate" name="nodeId" options={nodeOptions} />
            </FormRow>
            <LevelizationForm node={node} levelizeBy={levelizeBy} />
            {isTeaPlot && !costOverride && (
              <FormRow label="Upstream Costs Breakdown" name="upstreamCostsBreakdown">
                <input
                  {...register('upstreamCostsBreakdown')}
                  id="upstreamCostsBreakdown"
                  name="upstreamCostsBreakdown"
                  type="checkbox"
                  className="toggle toggle-sm toggle-success mt-1 w-full truncate"
                />
              </FormRow>
            )}
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

TEAPlotForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  plotType: PropTypes.string,
};

export default TEAPlotForm;
