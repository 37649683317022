import { getDefaultLevelization } from '@/utils/pathwayUtils';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormRow from './form/FormRow';
import Select from './form/Select';
import PropTypes from 'prop-types';

const LevelizationForm = ({ node, levelizeBy, levelize, showUnit = true }) => {
  const { setValue } = useFormContext();

  const {
    levelizeBy: defaultLevelizeBy,
    levelizeUnit: defaultLevelizeUnit,
    levelizeByOptions,
    levelizeUnitOptions,
  } = getDefaultLevelization(node, levelizeBy);

  // when node is changed update default levelizeBy
  useEffect(() => {
    if (levelizeBy && levelizeBy !== defaultLevelizeBy) {
      setValue('levelizeBy', defaultLevelizeBy);
    }
  }, [defaultLevelizeBy, levelizeBy, setValue]);

  // when levelizeBy is changed update the default levelizeUnit
  useEffect(() => {
    setValue('levelizeUnit', defaultLevelizeUnit);
  }, [defaultLevelizeUnit, levelizeBy, defaultLevelizeBy, setValue]);

  return (
    <FormRow label="Functional Unit" name="levelizeBy" className={`${levelize === 'false' ? 'hidden' : ''}`}>
      <div className="flex join items-center w-full mb-2 rounded-none">
        <Select name="levelizeBy" className="join-item w-full truncate" options={levelizeByOptions} />
        {showUnit && <Select name="levelizeUnit" className="join-item truncate" options={levelizeUnitOptions} />}
      </div>
    </FormRow>
  );
};

LevelizationForm.propTypes = {
  node: PropTypes.object,
  levelizeBy: PropTypes.string,
  levelize: PropTypes.string,
  showUnit: PropTypes.bool,
};

export default LevelizationForm;
