import { runSystem, getSystem, getSystemParams } from '@/api/systems';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { SYSTEM_STATUS, CHECK_FIRST_DELAY, CHECK_SECOND_DELAY, CHANGE_DELAY_AFTER } from '@/consts';
import { keyBy, runInterval } from '@/utils/miscUtils';
import { resourcesActions } from './resourcesStore';
import { toast } from '@/utils/toast';
import { derivedCacheActions } from './derivedCacheStore';

const initialValues = {
  id: null,
  status: null,
  params: null,
  name: null,
  param_values: null,
  results: null,
  system_type: null,
  rows: null,
};

const useSystemStore = create()(immer(() => initialValues));
const { setState: set, getState: get } = useSystemStore;

// actions
export const systemActions = {
  init: async (system, paramValues) => {
    systemActions.clear();
    derivedCacheActions.clear();
    const { id, status, params, name, results, system_type } = system;

    set({ id, status, params, name, paramValues, results, system_type });

    if (status === SYSTEM_STATUS.running) {
      systemActions.checkSystemStatusPeriodically();
    }
  },

  updateParams: async paramValues => {
    set({ paramValues });
  },
  run: async () => {
    const { id, params, name, system_type } = get();
    await runSystem(id, { system_type, params, name });
    set({ status: SYSTEM_STATUS.running });
    resourcesActions.updateSystem({ id, status: SYSTEM_STATUS.running });
    systemActions.checkSystemStatusPeriodically();
  },

  checkSystemStatus: async () => {
    const { id } = get();

    try {
      const { data: system } = await getSystem(id);
      const { status, error } = system;

      if (status === SYSTEM_STATUS.succeeded || status === SYSTEM_STATUS.failed) {
        resourcesActions.updateSystem({ id, status });
        const { data: { params } = {} } = await getSystemParams(id);
        systemActions.init(system, params);

        if (error) {
          toast.error(error);
        }

        return true;
      }
    } catch (err) {
      set({ status: SYSTEM_STATUS.failed });
      resourcesActions.updateSystem({ id, status: SYSTEM_STATUS.failed });
      return true;
    }

    return false;
  },

  checkSystemStatusPeriodically: () => {
    runInterval(systemActions.checkSystemStatus, CHECK_FIRST_DELAY, CHECK_SECOND_DELAY, CHANGE_DELAY_AFTER);
  },
  clear: () => set(initialValues, true),
};

// selectors
export const useSystemParams = () => useSystemStore(store => store.params);
export const useSystemValues = () => useSystemStore(store => store.paramValues);
export const useSystemStatus = () => useSystemStore(store => store.status);
export const useSystemId = () => useSystemStore(store => store?.id);
export const useSystemType = () => useSystemStore(store => store?.system_type);

export const useSystemLocations = () =>
  useSystemStore(store => {
    const { params, paramValues } = store;
    const locationParams = params?.filter(param => param.type === 'location');

    if (!locationParams?.length) {
      return [];
    }

    const locationParamsByName = keyBy(locationParams, 'name');

    if (!paramValues.if_different_location_solar?.primary?.value) {
      delete locationParamsByName.location_solar;
    }

    if (!paramValues.if_different_location_wind?.primary?.value) {
      delete locationParamsByName.location_wind;
    }

    return (
      Object.values(locationParamsByName).map(
        param => paramValues[param.name]?.primary?.value || param[param?.name]?.primary?.default,
      ) ?? []
    );
  });

export default useSystemStore;
