import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import FormRow from '@/components/core/form/FormRow';
import { getUnitOptions } from '@/utils/pathwayUtils';
import debounce from 'debounce';
import Select from '@/components/core/form/Select';
import { FormProvider } from 'react-hook-form';
import { useEmissionTypes, useUnits } from '@/stores/comparisonStore';

const LCAComparisonForm = ({ defaultValues, onSubmit }) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, register } = methods;
  const units = useUnits();
  const unitOptions = getUnitOptions(units);
  const emissionTypeOptions = useEmissionTypes();
  const debouncedSave = debounce(() => handleSubmit(onSubmit)(), 10);

  return (
    <div className="group node">
      <FormProvider {...methods}>
        <form onChange={debouncedSave}>
          <div className="mt-2 grid grid-cols-5 gap-2">
            <FormRow label="Unit" name="levelizationUnit">
              <Select className="w-full truncate" name="levelizationUnit" options={unitOptions} />
            </FormRow>
            <FormRow label="Y axis" name="emissionType">
              <Select className="w-full truncate" name="emissionType" options={emissionTypeOptions} />
            </FormRow>
          </div>
          <div className="flex p-2 mr-2 mt-2">
            <FormRow label="Add Scope 3 breakdown" name="scope3Breakdown">
              <input
                {...register('scope3Breakdown')}
                id="scope3Breakdown"
                name="scope3Breakdown"
                type="checkbox"
                className="toggle toggle-sm toggle-success mt-1 truncate w-full"
              />
            </FormRow>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

LCAComparisonForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default LCAComparisonForm;
