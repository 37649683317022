import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { registryActions, useActiveCategory } from '@/stores/registryStore';
import SearchInput from '../core/form/SearchInput';
import usePrevious from '@/hooks/usePrevious';
import { memo, useCallback, useEffect } from 'react';

const RegistryForm = ({ defaultValues, onSubmit }) => {
  const activeCategory = useActiveCategory();
  const prevActiveCategory = usePrevious(activeCategory);
  const methods = useForm({ defaultValues, mode: 'onChange' });
  const { handleSubmit, setValue, getValues } = methods;

  const submit = useCallback(
    data => {
      if (data.term.length && activeCategory !== 'All') {
        registryActions.setActiveCategory('All');
      } else if (data?.term?.length === 0 && activeCategory === 'All') {
        registryActions.setActiveCategory(prevActiveCategory);
      }
      onSubmit(data);
    },
    [activeCategory, onSubmit, prevActiveCategory],
  );

  useEffect(() => {
    if (activeCategory !== 'All' && prevActiveCategory) {
      const values = getValues();
      setValue('term', '');
      submit({ ...values, ...{ term: '' } });
    }
  }, [activeCategory, prevActiveCategory, setValue, getValues, submit]);

  return (
    <FormProvider {...methods}>
      <form className="module-search" onSubmit={handleSubmit(submit)} onChange={handleSubmit(submit)}>
        <SearchInput name="term" />
      </form>
    </FormProvider>
  );
};

RegistryForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

const MemoizedRegistryForm = memo(RegistryForm);

export default MemoizedRegistryForm;
