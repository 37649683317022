import { useParamsContext } from '@/components/providers/useParamsContext';
import { derivedCacheActions } from '@/stores/derivedCacheStore';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

export const useDerivedValueFetch = ({ name, shouldFetch, changedDependencies }) => {
  const [isLoading, setIsLoading] = useState(true);
  const timeoutRef = useRef(null);
  const { fetchDerivedValues } = useParamsContext();
  const urlParams = useParams();

  useEffect(() => {
    const fetchDerivedValue = async () => {
      try {
        setIsLoading(true);
        const { data } = await fetchDerivedValues(name, urlParams);
        derivedCacheActions.setDerivedParam(name, data);
      } catch (error) {
        derivedCacheActions.setDerivedParam(name, null);
      } finally {
        setIsLoading(false);
      }
    };

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (shouldFetch) {
      timeoutRef.current = setTimeout(fetchDerivedValue, 500);
    } else {
      setIsLoading(false);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [shouldFetch, changedDependencies, name, fetchDerivedValues, urlParams]);

  return { isLoading };
};
