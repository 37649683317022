import { createContext, useContext } from 'react';

export const ParamsContext = createContext(null);

export const useParamsContext = () => {
  const context = useContext(ParamsContext);
  if (!context) {
    throw new Error('useParamsContext must be used within a ParamsProvider');
  }
  return context;
};
