import PropTypes from 'prop-types';
import MCField from '../parameters/fields/MCField';

const MCFields = ({ name, mcMin, mcMax }) => {
  return (
    <div className="flex gap-4 items-start">
      <MCField label="Min" param={mcMin} type="mc_min" name={name} />
      <MCField label="Max" param={mcMax} type="mc_max" name={name} />
    </div>
  );
};

MCFields.propTypes = {
  name: PropTypes.string,
  mcMin: PropTypes.object,
  mcMax: PropTypes.object,
};

export default MCFields;
