import { redirect } from 'react-router-dom';
import PathwayRoute from '@/routes/build/PathwayRoute';
import NodeRoute from '@/routes/NodeRoute';
import EditPathwayModal from '@/components/pathways/EditPathwayModal';
import { deletePathway, getPathway, getPathwayParams } from '@/api/pathways';
import { getCase } from '@/api/cases';
import { pathwayActions } from '@/stores/pathwayStore';
import { caseActions } from '@/stores/caseStore';
import { getNodeWithInfo, patchNode } from '@/api/nodes';
import BuildRoute from './BuildRoute';
import SystemRoute from './SystemRoute';
import { deleteSystem, getSystem, getSystemParams } from '@/api/systems';
import { systemActions } from '@/stores/systemStore';
import EditSystemModal from '@/components/systems/EditSystemModal';
import { resourcesActions } from '@/stores/resourcesStore';
import { getProjects } from '@/api/projects';
import TitleEditForm from '@/components/nodes/TitleEditForm';
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/outline';
import { nodeActions } from '@/stores/nodeStore';
import PathwaySettings from '@/components/pathways/PathwaySettings';

const buildRouter = {
  path: '/projects/:projectId/build',
  element: <BuildRoute />,
  handle: {
    title: _ => {
      return { title: `Build` };
    },
  },
  children: [
    {
      path: '/projects/:projectId/build/pathways/:pathwayId',
      handle: {
        crumb: data => [
          'Pathways',
          data?.pathway?.name,
          data?.pathway?.locked ? <LockClosedIcon className="h-4 w-4" /> : <LockOpenIcon className="h-4 w-4" />,
        ],
        title: data => {
          return {
            title: `Build Pathway: ${data?.pathway?.name}`,
          };
        },
        settings: () => <PathwaySettings />,
      },
      id: 'pathway',
      element: <PathwayRoute />,
      errorElement: <PathwayRoute />,
      loader: async ({ params: { pathwayId } }) => {
        const { data: pathway } = await getPathway(pathwayId);
        const { data: caseData } = await getCase(pathway.case_id);
        caseActions.init(caseData);
        await pathwayActions.init(pathway);

        return { pathway, caseData };
      },
      children: [
        {
          path: '/projects/:projectId/build/pathways/:pathwayId/edit',
          element: <EditPathwayModal title="Edit Pathway" />,
          loader: async ({ params: { pathwayId } }) => {
            const [
              { data: pathway },
              {
                data: { params },
              },
            ] = await Promise.all([getPathway(pathwayId), getPathwayParams(pathwayId)]);

            return { pathway, params };
          },
          action: EditPathwayModal.editAction,
        },
        {
          path: '/projects/:projectId/build/pathways/:pathwayId/duplicate',
          element: <EditPathwayModal title="Duplicate Pathway" />,
          loader: async ({ params: { pathwayId } }) => {
            const [
              { data: pathway },
              {
                data: { results: projects },
              },
            ] = await Promise.all([getPathway(pathwayId), getProjects()]);

            return { pathway, projects };
          },
          action: EditPathwayModal.duplicateAction,
        },
        {
          path: '/projects/:projectId/build/pathways/:pathwayId/:caseId/nodes/:nodeId',
          id: 'node',
          element: <NodeRoute />,
          loader: async ({ params: { pathwayId, caseId, nodeId } }) => {
            const { node, caseNode, params } = await getNodeWithInfo(pathwayId, caseId, nodeId);

            nodeActions.init(node, caseNode, params);
            return { node, caseNode, params };
          },
          children: [
            {
              path: 'edit',
              element: <TitleEditForm />,
              loader: async ({ params: { pathwayId, caseId, nodeId } }) => {
                const { node } = await getNodeWithInfo(pathwayId, caseId, nodeId);
                return { node };
              },
              action: async ({ request, params }) => {
                const form = await request.formData();
                const data = Object.fromEntries(form);
                const { caseId, nodeId } = params;

                await patchNode(caseId, nodeId, data);
                return redirect('..');
              },
            },
          ],
        },
        {
          path: '/projects/:projectId/build/pathways/:pathwayId/destroy',
          action: async ({ params }) => {
            const { projectId, pathwayId } = params;
            const pathways = resourcesActions.getPathways();
            const filteredPathways = pathways.filter(pathway => pathway.id !== pathwayId);
            const currentPathwayId = filteredPathways?.[0]?.id;
            const url = currentPathwayId
              ? `/projects/${projectId}/build/pathways/${currentPathwayId}`
              : `/projects/${projectId}/build`;

            await deletePathway(pathwayId);

            return redirect(url);
          },
        },
      ],
    },
    {
      path: '/projects/:projectId/build/pathways/new',
      element: <EditPathwayModal title="New Pathway" />,
      action: EditPathwayModal.addAction,
    },
    {
      path: '/projects/:projectId/build/systems/:systemId',
      id: 'system',
      element: <SystemRoute />,
      errorElement: <SystemRoute />,
      handle: {
        crumb: data => ['Systems', data?.system?.name],
        title: data => {
          return { title: `Build System: ${data?.system?.name}` };
        },
      },
      loader: async ({ params: { systemId } }) => {
        const [
          { data: system },
          {
            data: { params },
          },
        ] = await Promise.all([getSystem(systemId), getSystemParams(systemId)]);

        await systemActions.init(system, params);
        return { system };
      },
      children: [
        {
          path: '/projects/:projectId/build/systems/:systemId/edit',
          element: <EditSystemModal action="edit" title="Rename System" />,
          loader: async ({ params: { systemId } }) => {
            const { data: system } = await getSystem(systemId);
            return { system };
          },
          action: EditSystemModal.editAction,
        },
        {
          path: '/projects/:projectId/build/systems/:systemId/duplicate',
          element: <EditSystemModal action="duplicate" title="Duplicate System" />,
          loader: async ({ params: { systemId } }) => {
            const [
              { data: system },
              {
                data: { results: projects },
              },
            ] = await Promise.all([getSystem(systemId), getProjects()]);

            return { projects, system };
          },
          action: EditSystemModal.duplicateAction,
        },
        {
          path: '/projects/:projectId/build/systems/:systemId/destroy',
          action: async ({ params }) => {
            const { projectId, systemId } = params;
            const systems = resourcesActions.getSystems();
            const filteredSystems = systems.filter(system => system.id !== systemId);
            const currentSystemId = filteredSystems?.[0]?.id;
            const url = currentSystemId
              ? `/projects/${projectId}/build/systems/${currentSystemId}`
              : `/projects/${projectId}/build`;

            await deleteSystem(systemId);
            return redirect(url);
          },
        },
      ],
    },
    {
      path: '/projects/:projectId/build/systems/new',
      element: <EditSystemModal action="add" title="New System" />,
      action: EditSystemModal.addAction,
    },
  ],
};

export default buildRouter;
