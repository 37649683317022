import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import userApi from '@/api/user';

const initialValues = {
  walkthroughs: {
    comparison: true,
  },
  theme: 'sesame-small',
  appVersion: null,
  gettingStarted: {},
  isPaletteOpen: true,
  paletteHeight: {},
};

const useSettingsStore = create()(
  immer(
    persist(() => initialValues, {
      name: 'settingsStore',
      version: 1,
      // only persist these settings
      partialize: ({ theme, gettingStarted, walkthroughs }) => ({ theme, gettingStarted, walkthroughs }),
    }),
  ),
);

const { setState: set } = useSettingsStore;

// actions
export const settingsActions = {
  disableWalkthrough: name =>
    set(state => {
      state.walkthroughs[name] = false;
    }),

  enableWalkthrough: name =>
    set(state => {
      state.walkthroughs[name] = true;
    }),

  updateTheme: theme => {
    set({ theme });
  },

  fetchSettings: async () => {
    const { data } = await userApi.getSettings();
    const { maintenance_message, maintenance_window, revision, version } = data;
    const state = {
      appVersion: version,
      revision,
    };

    if (maintenance_window) {
      state.maintenance = {
        message: maintenance_message,
        window: maintenance_window,
      };
    }

    set(state);
  },

  setGettingStarted: (projectId, settings) =>
    set(state => {
      state.gettingStarted[projectId] = {
        ...state.gettingStarted[projectId],
        ...settings,
      };
    }),

  closeGettingStartedPrompt: projectId => {
    settingsActions.setGettingStarted(projectId, { isPromptClosed: true });
  },

  resetGettingStarted: projectId =>
    set(state => {
      state.gettingStarted[projectId] = {
        isPromptClosed: false,
        skippedSteps: [],
      };
    }),

  skipGettingStartedStep: (projectId, stepIndex) =>
    set(state => {
      const projectSettings = state.gettingStarted[projectId] || { skippedSteps: [] };
      if (!projectSettings.skippedSteps.includes(stepIndex)) {
        projectSettings.skippedSteps.push(stepIndex);
      }
      state.gettingStarted[projectId] = projectSettings;
    }),
  closePalette: () => {
    set({ isPaletteOpen: false });
  },
  openPalette: () => {
    set({ isPaletteOpen: true });
  },
  setPaletteHeight: (category, height) => {
    set(state => {
      state.paletteHeight[category] = height;
    });
  },
};

// selectors
export const useTheme = () => useSettingsStore(store => store.theme);
export const useAppVersion = () => useSettingsStore(store => store.appVersion);
export const useMaintenance = () => useSettingsStore(store => store.maintenance);
export const useIsPaletteOpen = () => useSettingsStore(store => store.isPaletteOpen);
export const useIsWalkthroughEnabled = type =>
  useSettingsStore(store => {
    return store?.walkthroughs?.[type] === undefined || store?.walkthroughs?.[type] === true;
  });
export const useGettingStartedSettings = projectId =>
  useSettingsStore(store => store.gettingStarted[projectId] || { isPromptClosed: false, skippedSteps: [] });
export const usePaletteHeight = category => useSettingsStore(store => store.paletteHeight[category] || 160);

export default useSettingsStore;
