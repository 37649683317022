import PropTypes from 'prop-types';
import TimeSeriesFields from './TimeSeriesFields';

const DerivedFields = ({ paramSchemas, paramValues, index, parentName }) => {
  const prefix = parentName ? `${parentName}.${index}.` : '';
  const validParamSchemas = paramSchemas.filter(param => paramValues[param.name]);

  if (validParamSchemas.length === 0) {
    return null;
  }

  return (
    <TimeSeriesFields
      key={`${prefix}.${validParamSchemas[0].name}`}
      paramSchemas={validParamSchemas}
      paramValues={paramValues}
      index={index}
      parentName={parentName}
    />
  );
};

DerivedFields.propTypes = {
  paramSchemas: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  paramValues: PropTypes.object,
  index: PropTypes.number,
  parentName: PropTypes.string,
};

export default DerivedFields;
