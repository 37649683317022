import PropTypes from 'prop-types';
import InputField from '../parameters/fields/InputField';
import SelectField from '../parameters/fields/SelectField';
import ToggleField from '../parameters/fields/ToggleField';
import GroupField from '../parameters/fields/GroupField';
import LocationField from '../parameters/fields/LocationField';

const paramTypes = {
  numeric: InputField,
  string: InputField,
  options: SelectField,
  boolean: ToggleField,
  group: GroupField,
  location: LocationField,
};

const PathwayFields = ({ params, paramSchemas }) => {
  return paramSchemas
    .filter(paramSchema => paramTypes[paramSchema.type])
    .map(paramSchema => {
      const { name, type } = paramSchema;
      const FieldComponent = paramTypes[type];
      const classes = `mx-auto pb-0 mt-6 mb-2`;
      const param = params[name];
      return (
        param && (
          <div key={name} className={classes}>
            <FieldComponent name={name} paramSchema={paramSchema} param={param} />
          </div>
        )
      );
    });
};

PathwayFields.propTypes = {
  params: PropTypes.object,
  paramSchemas: PropTypes.arrayOf(PropTypes.object),
};

export default PathwayFields;
