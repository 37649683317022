import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import PathwayFields from './PathwayFields';
import ParamsProvider from '../providers/ParamProvider';
import { patchPathwayParams } from '@/api/pathways';
import { caseActions } from '@/stores/caseStore';

const updatePathwayParams = async (params, urlParams) => {
  const { pathwayId } = urlParams;
  const { data } = await patchPathwayParams(pathwayId, params);
  caseActions.markUnbalanced();

  return data;
};

const PathwaySettingsForm = ({ params, paramSchemas }) => {
  const methods = useForm();
  const { handleSubmit, reset } = methods;

  const onInnerSubmit = async data => {
    reset(data);
  };

  return (
    <ParamsProvider patch={updatePathwayParams}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onInnerSubmit)} noValidate className="h-full">
          <PathwayFields params={params} paramSchemas={paramSchemas} />
        </form>
      </FormProvider>
    </ParamsProvider>
  );
};

PathwaySettingsForm.propTypes = {
  params: PropTypes.object,
  paramSchemas: PropTypes.arrayOf(PropTypes.object),
};

export default PathwaySettingsForm;
