import { duplicateCase } from '@/api/cases';
import { createPathway, patchPathway } from '@/api/pathways';
import { isBuildPath } from '@/utils/miscUtils';
import PropTypes from 'prop-types';
import {
  Link,
  useLoaderData,
  useActionData,
  useParams,
  redirect,
  json,
  useSubmit,
  useRouteLoaderData,
} from 'react-router-dom';
import SubmitButton from '../core/form/SubmitButton';
import { FormProvider, useForm } from 'react-hook-form';
import Select from '../core/form/Select';
import Tooltip from '../core/Tooltip';

const EditPathwayModal = ({ title }) => {
  const { pathway, projects, params } = useLoaderData() ?? {};
  const { pathwayParams } = useRouteLoaderData('root');

  const methods = useForm();
  const { register, handleSubmit } = methods;
  const submit = useSubmit();
  const errors = useActionData();
  const name = pathway?.name ?? '';
  const caseId = pathway?.case_id ?? '';
  const { projectId } = useParams();
  const projectOptions = projects?.map(({ id, name }) => ({ label: name, value: id }));
  const defaultName = projects?.length ? `${name} [duplicate]` : name;
  const defaultStartYear = params?.analysis_start_year?.primary?.value ?? new Date().getFullYear();

  const onSubmit = (data, event) => {
    event.preventDefault();
    submit(data, {
      method: 'put',
      encType: 'application/json',
    });
  };

  return (
    <FormProvider {...methods}>
      <dialog className="modal" open>
        <div className="modal-box">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="font-bold text-lg">{title}</h3>
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">Name</span>
              </div>
              <input
                type="text"
                {...register('name')}
                placeholder="Enter pathway name"
                className="input input-bordered input-sm w-full"
                defaultValue={defaultName}
              />
              <div className="label">
                <span className="label-text-alt text-error">{errors?.name?.[0]}</span>
              </div>
              <input type="hidden" {...register('curName')} defaultValue={name} />
              <input type="hidden" {...register('caseId')} defaultValue={caseId} />
            </label>
            {!projectOptions?.length && (
              <label className="form-control w-full">
                <div className="label">
                  <div className="flex items-center gap-2">
                    <span className="label-text">Analysis Start Year</span>
                    <Tooltip
                      label={pathwayParams?.analysis_start_year?.label}
                      text={pathwayParams?.analysis_start_year?.tooltip}
                      source={pathwayParams?.analysis_start_year?.source}
                    />
                  </div>
                </div>
                <input
                  type="text"
                  {...register('params.analysis_start_year.primary.value', { valueAsNumber: true, required: true })}
                  placeholder="Enter analysis start year"
                  className="input input-bordered input-sm w-full"
                  defaultValue={defaultStartYear}
                />
                <div className="label">
                  <span className="label-text-alt text-error">
                    {errors?.params?.analysis_start_year?.primary?.value?.[0]}
                  </span>
                </div>
              </label>
            )}
            {projectOptions?.length && (
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">Project</span>
                </div>
                <Select
                  className="w-full select-sm"
                  name="project_id"
                  defaultValue={projectId}
                  options={projectOptions}
                />
              </label>
            )}
            <div className="modal-action">
              <Link className="btn btn-sm btn-secondary" to={-1} replace>
                Cancel
              </Link>
              <SubmitButton label="Save" />
            </div>
          </form>
        </div>
      </dialog>
    </FormProvider>
  );
};

EditPathwayModal.propTypes = {
  title: PropTypes.string,
};

EditPathwayModal.addAction = async ({ request, params }) => {
  const { projectId } = params;
  const pathway = await request.json();
  const buildPath = isBuildPath(request.url);
  const actionPath = buildPath ? `/projects/${projectId}/build/pathways` : `/projects/${projectId}/dashboard`;

  try {
    const { data } = await createPathway(projectId, pathway);
    return redirect(buildPath ? `${actionPath}/${data.id}` : actionPath);
  } catch (error) {
    return json(error.response.data);
  }
};

EditPathwayModal.editAction = async ({ request, params }) => {
  const { projectId, pathwayId } = params;
  const pathway = await request.json();
  const matchBuild = isBuildPath(request.url);
  const actionPath = matchBuild ? `/projects/${projectId}/build` : `/projects/${projectId}/dashboard`;

  try {
    await patchPathway(pathwayId, pathway);
    return redirect(matchBuild ? `${actionPath}/pathways/${pathwayId}` : actionPath);
  } catch (error) {
    return json(error.response.data);
  }
};

EditPathwayModal.duplicateAction = async ({ request, params }) => {
  const { projectId, pathwayId } = params;
  const pathway = await request.json();
  const { name, curName, caseId, project_id } = pathway;
  const matchBuild = isBuildPath(request.url);
  const actionPath = matchBuild ? `/projects/${projectId}/build` : `/projects/${projectId}/dashboard`;

  if (name === curName) {
    return json({ name: 'Please choose different name' });
  }

  try {
    const { data } = await duplicateCase(caseId, { name, project_id });
    return redirect(matchBuild ? `${actionPath}/pathways/${data.pathway_id}` : actionPath);
  } catch (err) {
    return redirect(
      matchBuild ? `${actionPath}/pathways/${pathwayId}/duplicate` : `${actionPath}/${pathwayId}/duplicate`,
    );
  }
};

export default EditPathwayModal;
