import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

const initialValues = {
  params: {},
};

const useDerivedCacheStore = create()(immer(() => initialValues));
const { setState: set } = useDerivedCacheStore;

export const derivedCacheActions = {
  setDerivedParam: (paramName, paramValue) => {
    set(state => {
      state.params[paramName] = paramValue;
    });
  },
  clear: () => set(initialValues, true),
};

export const useDerivedParam = name => useDerivedCacheStore(store => store.params[name]);

export default useDerivedCacheStore;
