import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { Await, useAsyncValue, useMatches } from 'react-router-dom';

const PageTitleContent = ({ match }) => {
  const data = useAsyncValue();
  const titleData = match.handle.title(data);
  const settingsComponent = match?.handle?.settings?.(data);

  return (
    <div className="flex justify-between w-full">
      <div className="flex">
        <span>{titleData?.title}</span>
        {titleData?.actions?.map((ActionComponent, index) => (
          <ActionComponent key={`title-action-${index}`} />
        ))}
      </div>
      {settingsComponent}
    </div>
  );
};

PageTitleContent.propTypes = {
  match: PropTypes.object,
};

const PageTitle = ({ className }) => {
  const matches = useMatches();
  const titles = matches.filter(match => Boolean(match.handle?.title));

  if (titles.length === 0) return null;

  const lastTitle = titles[titles.length - 1];

  return (
    <div
      className={`flex justify-between items-center w-full p-4 text-xl font-semibold border-b border-gray-300 ${className}`}
    >
      <Suspense fallback={<span className="loading loading-dots loading-md"></span>}>
        <Await resolve={lastTitle?.data?.promise ?? lastTitle?.data} errorElement={<div>Error</div>}>
          <PageTitleContent match={lastTitle} />
        </Await>
      </Suspense>
    </div>
  );
};

PageTitle.propTypes = {
  className: PropTypes.string,
};

export default PageTitle;
