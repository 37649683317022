import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

const initialValues = {
  pending: null,
};

const useParamStore = create()(immer(() => initialValues));
const { setState: set } = useParamStore;

let timeout;

export const paramActions = {
  setSavePending: pending => {
    set({ pending });

    if (!pending) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        set({ pending: null });
      }, 3000);
    }
  },
  clear: () => set(initialValues, true),
};

export const useIsSavePending = () => useParamStore(store => store.pending);

export default useParamStore;
