import { useLocation, useParams, useRouteError } from 'react-router-dom';
import ProjectSidebarLinks from './ProjectSidebarLinks';
import AppVersion from './AppVersion';
import FeedbackButton from './FeedbackButton';
import { getBottomLinks, getTopLinks } from '@/utils/projectUtils';
import useToggle from '@/hooks/useToggle';
import NavLinkList from './NavLinkList';

const Sidebar = () => {
  const { isOpen } = useToggle();
  const location = useLocation();
  const { projectId } = useParams();
  const error = useRouteError();

  const isProjectRoute =
    location.pathname.startsWith('/projects/') &&
    projectId &&
    !location.pathname.endsWith(`${projectId}/edit`) &&
    !location.pathname.endsWith(`${projectId}/destroy`);

  const shouldShowSidebar = !error && isProjectRoute;

  const bottomLinks = getBottomLinks();
  const topLinks = getTopLinks();

  return (
    <nav
      className={`bg-slate-50 h-full flex flex-col gap-8 transition-all duration-300 ease-in-out ${isOpen ? 'w-24' : 'w-24'}`}
    >
      <NavLinkList links={topLinks} />
      {shouldShowSidebar && <ProjectSidebarLinks isCollapsed={isOpen} />}
      <div className="mt-auto flex flex-col gap-8 justify-between items-center">
        <NavLinkList links={bottomLinks} />

        <div className={`flex flex-col justify-between gap-2 pb-2 items-center`}>
          <FeedbackButton />
          <AppVersion />
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
