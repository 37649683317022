import axios from 'axios';

const { get, post, patch } = axios;

// nodes
export const createNode = (pathwayId, data) => post(`/v1/pathways/${pathwayId}/nodes/`, data);
export const patchNode = (caseId, nodeId, data) => patch(`/v1/cases/${caseId}/nodes/${nodeId}/`, data);
export const getNode = (pathwayId, nodeId) => get(`/v1/pathways/${pathwayId}/nodes/${nodeId}/`);
export const removeNode = (pathwayId, nodeId) => axios.delete(`/v1/pathways/${pathwayId}/nodes/${nodeId}/`);
export const getCaseNode = (caseId, nodeId) => get(`/v1/cases/${caseId}/nodes/${nodeId}/`);

export const getNodeParams = (caseId, nodeId) => get(`/v1/cases/${caseId}/nodes/${nodeId}/params/`);
export const patchNodeParams = (caseId, nodeId, data) => patch(`/v1/cases/${caseId}/nodes/${nodeId}/params/`, data);

export const getNodeWithInfo = async (pathwayId, caseId, nodeId) => {
  const [
    { data: node },
    { data: caseNode },
    {
      data: { params },
    },
  ] = await Promise.all([getNode(pathwayId, nodeId), getCaseNode(caseId, nodeId), getNodeParams(caseId, nodeId)]);

  const { name } = caseNode;

  node.label = name || node.label;

  return { node, caseNode, params };
};
export const getDerivedValue = (caseId, nodeId, paramName) =>
  get(`/v1/cases/${caseId}/nodes/${nodeId}/params/${paramName}/derived_value/`);
