import PropTypes from 'prop-types';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';

export const ScrollButton = ({ onClick, children }) => (
  <button
    onClick={onClick}
    type="button"
    className="flex 2xl:hidden transition-all transform shadow-md p-2 text-black hover:text-gray-500"
  >
    {children}
  </button>
);

const ScrollableList = ({ children, onScrollLeft, onScrollRight }) => (
  <div className="flex overflow-hidden items-center">
    <ScrollButton onClick={onScrollLeft}>
      <ChevronLeftIcon className="h-5 w-5" />
    </ScrollButton>

    {children}

    <ScrollButton onClick={onScrollRight}>
      <ChevronRightIcon className="h-5 w-5" />
    </ScrollButton>
  </div>
);

ScrollableList.propTypes = {
  children: PropTypes.node.isRequired,
  onScrollLeft: PropTypes.func.isRequired,
  onScrollRight: PropTypes.func.isRequired,
};

ScrollButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ScrollableList;
