import { useLCAPlot, useNodeById } from '@/stores/analysisStore';
import WaterfallChart from '../../charts/WaterfallChart';
import { analysisActions } from '@/stores/analysisStore';
import LCAPlotForm from './LCAPlotForm';
import { getLCAYaxisLabel } from '@/utils/analysisUtils';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import AnalysisBox from '@/components/core/AnalysisBox';

const LCAPlot = () => {
  const plot = useLCAPlot();
  const { nodeId, results } = plot;
  const node = useNodeById(nodeId);
  const updatePlot = formData => analysisActions.updatePlot('lca', formData);

  return (
    <AnalysisContainer title="Emissions Analysis">
      <AnalysisBox>
        {results && (
          <WaterfallChart data={results} label={node.label} yAxisLabel={`Emissions\n[${getLCAYaxisLabel(plot)}]`} />
        )}
        {node ? (
          <LCAPlotForm onSubmit={updatePlot} defaultValues={plot} />
        ) : (
          <span className="loading loading-dots loading-md"></span>
        )}
      </AnalysisBox>
    </AnalysisContainer>
  );
};

export default LCAPlot;
