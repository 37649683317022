export default {
  title: "What's new in Sesame 0.2.5 🎉",
  items: [
    {
      title: 'TEA Improvements: default pathway monetary year, profitability analysis',
      description: 'The cash flow analysis in all modules of a pathway now use the Analysis Start Year as a monetary year reference. A profitability analysis can now be performed in each Module. The total asset costs and financial results are available in the Analyze tab.',
    },
    {
      title: 'Standalone Carbon Capture Modules for Thermal Processes',
      description:
        'Pair carbon capture modules with processes like steam methane reforming and use water knockout/compressor units to liquify the the captured CO2.',
    },
    {
      title: 'Electrolyzer degradation in the Hydrogen System.',
      description: 'Degradation can now be specified!',
    },
    {
      title: 'New Sustainable Aviation Fuel Modules',
      description:
        ' New Sustainable Aviation Fuel Modules** Alcohol to Jet (AtJ) and Biomass to Liquids via Fischer Tropsch (BtL) are now included in the Sesame Library',
    },
    {
      title: 'Updated Module Palette Look',
      description: 'The Module Palette has been updated the provide users with more space on the Canvas.',
    },
  ],
};
