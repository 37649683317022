import { PencilSquareIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PanelHeader = ({ children, title, isEditable }) => {
  const nodeContainerClasses = 'group-[.node]:border-transparent group-[.node]:bg-white';
  const nodeTitleClasses = 'group-[.node]:font-semibold group-[.node]:flex-1 group-[.node]:text-center';
  const actionsClasses = 'group-[.node]:justify-between';

  return (
    <div className={`flex flex-col justify-between pt-4 pb-2 px-2 sticky top-0 bg-canvas z-10 ${nodeContainerClasses}`}>
      <div className={`flex items-center justify-end ${actionsClasses}`}>{children}</div>
      <div className={`text-md inline-block font-bold align-middle" ${nodeTitleClasses}`}>
        {isEditable ? (
          <Link to="edit" className="group/edit hover:bg-gray-50 w-full inline-block relative py-1">
            <span>{title}</span>
            <PencilSquareIcon className="w-5 h-5 group-hover/edit:block hidden absolute right-1 top-1" />
          </Link>
        ) : (
          <>{title}</>
        )}
      </div>
    </div>
  );
};

PanelHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  isEditable: PropTypes.bool,
};

export default PanelHeader;
