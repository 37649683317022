import { useSystemParams, useSystemValues, useSystemId, systemActions } from '@/stores/systemStore';
import ParameterForm from '@/components/parameters/ParameterForm';
import { parseCategories } from '@/utils/paramsUtils';
import RunSystemButton from './RunSystemButton';
import OpenSystemAnalysisButton from './OpenSystemAnalysisButton';
import ToggleViewButton from './ToggleViewButton';
import { useState } from 'react';
import { VIEW_TYPES } from '@/consts';
import ParamsProvider from '../providers/ParamProvider';
import { getDerivedValue, patchSystemParams } from '@/api/systems';

const updateSystemParams = async (params, urlParams) => {
  const { systemId } = urlParams;
  const { data } = await patchSystemParams(systemId, params);
  systemActions.updateParams(data.params);
};

const fetchDerivedValues = async (name, urlParams) => {
  const { systemId } = urlParams;
  const data = await getDerivedValue(systemId, name);
  return data;
};

const SystemInputs = () => {
  const [viewType, setViewType] = useState(VIEW_TYPES.tabs);
  const paramSchemas = useSystemParams();
  const paramValues = useSystemValues();
  const categories = parseCategories(paramSchemas);
  const id = useSystemId();

  return (
    <ParamsProvider patch={updateSystemParams} fetchDerivedValues={fetchDerivedValues}>
      <ParameterForm
        key={id}
        className="pl-4 pr-4"
        paramValues={paramValues}
        categories={categories}
        paramSchemas={paramSchemas}
        title=""
        viewType={viewType}
        actions={
          <>
            <RunSystemButton />
            <OpenSystemAnalysisButton />
            <ToggleViewButton onToggle={setViewType} viewType={viewType} />
          </>
        }
      />
    </ParamsProvider>
  );
};

export default SystemInputs;
