import PropTypes from 'prop-types';
import { useState } from 'react';
import TimeSeriesResult from '@/components/systems/results/v2/TimeSeriesResult';
import { LINE_CHART } from '@/consts';
import { resolveParamName } from '@/utils/paramsUtils';
import { useDerivedParam } from '@/stores/derivedCacheStore';
import { useDerivedValueFetch } from '@/hooks/useDerivedValueFetch';

const TimeSeriesFields = ({ paramSchemas, paramValues, index, parentName }) => {
  const [paramName, setParamName] = useState(paramSchemas[0].name);
  const { primary: { value } = {} } = paramValues[paramName] ?? {};
  const graphOptions = paramSchemas.map(({ name, label }) => ({ value: name, label }));
  const name = resolveParamName(paramName, index, parentName);
  const { fetch, changed_dependencies: changedDependencies } = value ?? {};
  const results = useDerivedParam(name);
  const shouldFetch = fetch || !results;

  const { isLoading } = useDerivedValueFetch({
    name,
    shouldFetch,
    changedDependencies,
  });

  if (!results && !isLoading) {
    return null;
  }

  return (
    <div className="w-full">
      {isLoading ? (
        <div className="flex justify-center items-center">
          <span className="loading loading-spinner" />
        </div>
      ) : (
        <TimeSeriesResult
          key={name}
          onChange={name => setParamName(name)}
          useContainer={false}
          graphOptions={graphOptions}
          initPlot={paramName}
          initUnit={results?.unit}
          chartType={LINE_CHART}
          results={{ [paramName]: results }}
        />
      )}
    </div>
  );
};

TimeSeriesFields.propTypes = {
  paramSchemas: PropTypes.array.isRequired,
  paramValues: PropTypes.object,
  index: PropTypes.number,
  parentName: PropTypes.string,
};

export default TimeSeriesFields;
