import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import OpenIcon from '@/assets/open.svg?react';
import CloseIcon from '@/assets/close.svg?react';

const SwitchField = ({ param, name }) => {
  const { control, setValue } = useFormContext();
  const { label } = param;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value } }) => (
        <label className="cursor-pointer">
          <input
            type="checkbox"
            id={name}
            name={name}
            onChange={e => setValue(name, e.target.checked ? true : false, { shouldDirty: false })}
            checked={value === true}
            className="peer sr-only"
          />
          <div className="px-8 py-4 bg-gray-50 border-t text-right hover:bg-purple-50 text-gray-400 hover:text-black peer-checked:text-black peer-checked:bg-purple-50 w-full flex gap-3 justify-end">
            {label} {value == true ? <OpenIcon /> : <CloseIcon />}
          </div>
        </label>
      )}
    />
  );
};

SwitchField.propTypes = {
  param: PropTypes.object,
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.bool]),
  watchedFields: PropTypes.object,
};

export default SwitchField;
