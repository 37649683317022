import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import PanelHeader from '../core/PanelHeader';
import ParameterFields from './ParameterFields';
import { VIEW_TYPES } from '@/consts';
import { useIsSavePending } from '@/stores/paramStore';

const ParameterForm = ({
  paramValues,
  categories,
  actions,
  paramSchemas,
  title,
  className = '',
  viewType = VIEW_TYPES.grid,
}) => {
  const methods = useForm({ mode: 'onChange' });
  const isPending = useIsSavePending();
  const { handleSubmit, reset } = methods;

  const onInnerSubmit = async data => {
    reset(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onInnerSubmit)} noValidate className="flex flex-col min-h-0">
        <PanelHeader title={title}>
          <div className="flex mt-2">
            <div className="flex items-center">
              {isPending && <span className="text-xs mr-2 text-gray-500">Saving...</span>}
              {isPending === false && <span className="text-xs mr-2 text-gray-500">Saved</span>}
              {actions}
            </div>
          </div>
        </PanelHeader>
        <ParameterFields
          paramValues={paramValues}
          paramSchemas={paramSchemas}
          categories={categories}
          className={className}
          viewType={viewType}
        />
      </form>
    </FormProvider>
  );
};

ParameterForm.propTypes = {
  paramValues: PropTypes.object,
  paramSchemas: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  actions: PropTypes.node,
  className: PropTypes.string,
  categories: PropTypes.object,
  viewType: PropTypes.string,
};

export default ParameterForm;
