import {
  parseBalanceData,
  parseBalanceFormData,
  parseLCAData,
  parseLCAFormData,
  parseTEAFormData,
  parseTEAData,
  parseCashFlowsData,
  parseMCData,
  parseMCFormData,
  parseCharacteristicsData,
  sortNodesByAnchor,
  parseLevelization,
  parseSensitivityData,
  parseSensitivityLCAFormData,
  parseSensitivityTEAFormData,
  parseCharacteristicsFormData,
  parseCostBreakdownData,
  parseCostBreakdownFormData,
} from '@/utils/analysisUtils';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { subscribeWithSelector } from 'zustand/middleware';
import { keyBy } from '@/utils/miscUtils';
import { getAnalysisResults, getAnalysisTypeKeys, retrieveAnalysis } from '@/api/analyses';
import {
  applyNodeInfo,
  getDefaultLevelization,
  getEmissionTypes,
  getIOOptions,
  getUnitOptions,
  getUnitsByIO,
} from '@/utils/pathwayUtils';
import { getPathway } from '@/api/pathways';
import { getCase } from '@/api/cases';
import { workbenchActions } from './workbenchStore';
import { caseActions } from './caseStore';
import useAnalysisStatusStore, { analysisStatusActions } from './analysisStatusStore';
import { ANALYSIS_STATUS } from '@/consts';

const initialValues = {
  nodes: [],
  lca: {},
  tea: {},
  tea_breakdown: {},
  balance: {},
  characteristics: {},
  mc: {},
  cash_flows: {},
  sensitivity_lca: {},
  sensitivity_tea: {},
};

const chartParsers = {
  lca: parseLCAData,
  tea: parseTEAData,
  tea_breakdown: parseCostBreakdownData,
  balance: parseBalanceData,
  characteristics: parseCharacteristicsData,
  mc: parseMCData,
  cash_flows: parseCashFlowsData,
  sensitivity_lca: parseSensitivityData,
  sensitivity_tea: parseSensitivityData,
  sensitivity: parseSensitivityData,
};

const formDataParsers = {
  lca: parseLCAFormData,
  tea: parseTEAFormData,
  balance: parseBalanceFormData,
  characteristics: parseCharacteristicsFormData,
  tea_breakdown: parseCostBreakdownFormData,
  mc: parseMCFormData,
  cash_flows: parseTEAFormData,
  sensitivity_lca: parseSensitivityLCAFormData,
  sensitivity_tea: parseSensitivityTEAFormData,
};

const useAnalysisStore = create()(subscribeWithSelector(immer(() => initialValues)));
const { setState: set, getState: get } = useAnalysisStore;

// actions
export const analysisActions = {
  init: async pathwayId => {
    analysisActions.clear();

    const { data: pathway } = await getPathway(pathwayId);
    const [{ data: caseData }, { data: analysis }] = await Promise.all([
      getCase(pathway.case_id),
      retrieveAnalysis(pathway.analysis_id),
    ]);
    const { nodes: initialNodes } = pathway;
    const { anchor, node_info } = caseData;
    const nodes = applyNodeInfo(initialNodes, node_info);
    const sortedNodes = sortNodesByAnchor(nodes, anchor.node_id);

    set({
      id: analysis.id,
      caseId: pathway.case_id,
      name: pathway.name,
      pathwayId: pathway.id,
      nodes: keyBy(sortedNodes, 'id'),
    });

    // subscribe to analysis status changes
    const subscription = useAnalysisStatusStore.subscribe(
      state => state.analysisTypes,
      (analysisTypes, previousAnalysisTypes) => {
        Object.entries(analysisTypes).forEach(([type, analysisType]) => {
          const hasNewlySucceeded =
            analysisType?.status === ANALYSIS_STATUS.succeeded &&
            previousAnalysisTypes?.[type]?.status !== ANALYSIS_STATUS.succeeded;

          if (hasNewlySucceeded) {
            analysisActions.handleAnalysisSuccess(type);
          }
        });
      },
    );

    workbenchActions.init(pathway, caseData, analysis);
    caseActions.init(caseData);
    analysisStatusActions.addSubscription(subscription);

    return pathway;
  },

  handleAnalysisSuccess: type => {
    if (type === 'sensitivity') {
      analysisActions.addSensitivityPlot('lca');
      analysisActions.addSensitivityPlot('tea');
    } else {
      if (type === 'lca') {
        analysisActions.addPlot(type, { storeKeys: ['balance'] });
        analysisActions.addPlot(type, { levelize: true, storeKeys: ['lca'] });
      } else {
        analysisActions.addPlot(type, { storeKeys: getAnalysisTypeKeys(type) });
      }

      if (type === 'tea') {
        analysisActions.addPlot('tea_breakdown', { storeKeys: getAnalysisTypeKeys('tea_breakdown') });
      }
    }
  },

  getResults: async (plotType, params) => {
    const { id, nodes } = get();
    const resultParser = chartParsers[plotType];

    try {
      const { data } = await getAnalysisResults(id, plotType, params);
      return {
        results: resultParser(data, nodes, params),
        data,
      };
    } catch (err) {
      return { results: null, data: null };
    }
  },

  addSensitivityPlot: async type => {
    const { id, nodes } = get();
    const node = Object.values(nodes)?.[0] ?? {};
    const nodeId = node.id;
    const plotType = `sensitivity_${type}`;

    const unitsByIO = getUnitsByIO(node);
    const levelizeByOptions = getIOOptions(node);
    const levelizeBy = levelizeByOptions?.[0]?.value;
    const levelizeUnitOptions = getUnitOptions(unitsByIO?.[levelizeBy]?.units);
    const levelizeUnit = levelizeUnitOptions?.[0].value;

    const { io_name, io_type } = JSON.parse(levelizeBy);
    const unit = JSON.parse(levelizeUnit);

    const params = {
      node_id: nodeId,
      analysis_type: type,
      levelization: {
        io_name,
        io_type,
        unit,
      },
    };

    const { data } = await getAnalysisResults(id, plotType, params);
    const resultParser = chartParsers[plotType];
    const results = resultParser(data, nodes, params);

    set(state => {
      state[plotType] = { nodeId, results, ...data, levelizeBy, levelizeUnit };
    });
  },

  addPlot: async (plotType, { levelize = false, storeKeys = [] } = {}) => {
    const { id, nodes = {} } = get();
    const node = Object.values(nodes)?.[0] ?? {};
    const nodeId = node.id;
    const params = {
      node_id: nodeId,
    };

    if (levelize) {
      const { levelization } = getDefaultLevelization(node);
      params.levelization = levelization;
    }

    const { data } = await getAnalysisResults(id, plotType, params);
    const { levelization, ...rest } = data ?? {};
    const emissionTypeOptions = getEmissionTypes(Object.values(nodes));
    const emissionType = emissionTypeOptions?.[0]?.value;

    let plotData = {
      nodeId,
      emissionType,
    };

    if (levelization) {
      plotData = { ...plotData, ...parseLevelization(levelization), levelize: 'true' };
    }

    for (let key of storeKeys) {
      const resultParser = chartParsers[key];
      const results = resultParser(data, nodes, params);

      set(state => {
        state[key] = { ...plotData, results, ...rest };
      });
    }
  },

  updatePlot: async (plotType, plotFormData) => {
    const params = formDataParsers[plotType](plotFormData);

    const { results, data } = await analysisActions.getResults(plotType, params);
    const { levelization, ...rest } = data;
    let plotData = { ...plotFormData, results, ...rest };

    if (levelization) {
      plotData = { ...plotData, ...parseLevelization(levelization) };
    }

    if (data.distribution === 'levelized_cost') {
      plotData.scalar = data.scalar || 1;
    }

    set(state => {
      state[plotType] = plotData;
    });
  },

  clear: () => set(initialValues, true),
};

export const useAnalysis = () => useAnalysisStore(store => ({ id: store.id, name: store.name, caseId: store.caseId }));
export const useNodeById = nodeId => useAnalysisStore(store => store?.nodes?.[nodeId]);
export const useNodes = () => useAnalysisStore(store => Object.values(store?.nodes));
export const useLCAPlot = () => useAnalysisStore(store => store?.lca);
export const useTEAPlot = () => useAnalysisStore(store => store?.tea);
export const useMCPlot = () => useAnalysisStore(store => store?.mc);
export const useCashFlowsPlot = () => useAnalysisStore(store => store?.cash_flows);
export const useBalance = () => useAnalysisStore(store => store?.balance);
export const useCharacteristics = () => useAnalysisStore(store => store?.characteristics);
export const useCostBreakdown = () => useAnalysisStore(store => store?.tea_breakdown);
export const useSensitivityPlot = type => useAnalysisStore(store => store?.[`sensitivity_${type}`]);

export default useAnalysisStore;
