import PropTypes from 'prop-types';

const ErrorMessage = ({ text, className = '' }) =>
  text && (
    <div className={`label ${className}`}>
      <span className="label-text-alt text-error">{text}</span>
    </div>
  );

ErrorMessage.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default ErrorMessage;
