import { defaultViewport } from '@/components/pathways/pathwayOptions';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

// cache format
// pathways[pathwayId][nodeId] = {}

const initialValues = {
  pathways: {},
};

const usePathwaysCacheStore = create()(
  immer(
    persist(() => initialValues, {
      name: 'pathwaysCacheStore',
      version: 1,
    }),
  ),
);

const { setState: set, getState: get } = usePathwaysCacheStore;

// actions
export const pathwaysCacheActions = {
  cacheNodePosition: (pathwayId, nodeId, position) => {
    set(state => {
      if (!state.pathways[pathwayId]) {
        state.pathways[pathwayId] = {};
      }

      if (!state.pathways[pathwayId]?.nodes) {
        state.pathways[pathwayId].nodes = {};
      }

      state.pathways[pathwayId].nodes[nodeId] = position;

      if (!state.pathways[pathwayId].panel) {
        state.pathways[pathwayId].panel = defaultViewport;
      }
    });
  },

  cachePanePosition: (pathwayId, position) => {
    set(state => {
      if (!state.pathways[pathwayId]) {
        state.pathways[pathwayId] = {};
      }

      state.pathways[pathwayId].panel = position;
    });
  },
  getCachedNodePositions: pathwayId => {
    return get()?.pathways?.[pathwayId]?.nodes;
  },

  clearPathwayCache: pathwayId => {
    set(state => {
      delete state.pathways[pathwayId];
    });
  },
  clearNodeCache: (pathwayId, nodeId) => {
    set(state => {
      delete state.pathways?.[pathwayId]?.nodes?.[nodeId];
    });
  },
  clear: () => set(initialValues, true),
};

export const useCachedPanePosition = pathwayId => usePathwaysCacheStore(store => store?.pathways?.[pathwayId]?.panel);

export default usePathwaysCacheStore;
