import { Outlet, useParams } from 'react-router-dom';
import PathwayFlow from '@/components/pathways/PathwayFlow';
import PathwayRegistry from '@/components/pathways/PathwayRegistry';
import AnchorContainer from '@/components/nodes/AnchorContainer';
import { ReactFlowProvider } from '@xyflow/react';
import WalkthroughButton from '@/components/core/WalkthroughButton';
import StatusAlerts from '@/components/pathways/StatusAlerts';

const PathwayRoute = () => {
  const { nodeId } = useParams();
  const containerClasses = nodeId ? 'visible' : 'invisible !min-w-0';

  return (
    <ReactFlowProvider>
      <div className="flex-grow h-full flex relative">
        <div className="flex flex-col h-full flex-grow bg-white select-none">
          <PathwayRegistry />
          <div className="absolute z-[100] flex float-right w-full">
            <StatusAlerts />
          </div>
          <WalkthroughButton type="modules" />
          <PathwayFlow />
          <AnchorContainer />
        </div>
        <div
          className={`min-w-[34rem] overflow-hidden bg-transparent w-0 transition-[min-width] duration-200 border-l border-gray-200 z-[400] bg-white ${containerClasses}`}
        >
          <Outlet />
        </div>
      </div>
    </ReactFlowProvider>
  );
};

export default PathwayRoute;
