export const validatorRules = {
  numeric: () => value => (!isNaN(value) ? true : 'value has to be numeric'),
  integer: () => value => (!isNaN(value) && /^-?\d+$/.test(value) ? true : `value has to be an integer`),
};

export const parseErrors = error => {
  return error.details.reduce((acc, error) => {
    acc[error.context.key] = error.message;
    return acc;
  }, {});
};
