import { NavLink, Outlet, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import EmptyState from '@/components/pathways/EmptyState';

const AnalysesRoute = () => {
  const { pathname } = useLocation();
  const { projectId } = useParams();
  const displayEmptyState = pathname.match(/analysis$/);

  return (
    <div className="w-full !overflow-y-scroll bg-canvas flex-1">
      {displayEmptyState ? (
        <EmptyState title="In order to view an analysis you will need to build, balance and analyze a pathway.">
          <NavLink
            to={`/projects/${projectId}/dashboard/`}
            className="btn btn-primary rounded-md leading-6 font-semibold flex"
          >
            View Dashboard
          </NavLink>
        </EmptyState>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default AnalysesRoute;
