import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';
import ConversionField from '../ConversionField';
import { useEffect } from 'react';
import { isDefined } from '@/utils/miscUtils';

const GroupInputField = ({ paramSchema, param, name, parentName, onChange }) => {
  const { label, convertible_units: convertibleUnits, name: rawName } = paramSchema;
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const errorMessage = errors?.[parentName]?.message?.[rawName]?.primary?.value;
  const registration = register(name, { valueAsNumber: true });
  const { primary: { value, is_default: isDefault, default: defaultValue } = {}, unit } = param ?? {};

  const handleChange = event => {
    event.preventDefault();
  };

  const handleBlur = async event => {
    const prevValue = getValues(name);
    const value = Number(event.target.value);

    if (prevValue !== value) {
      setValue(name, value, { shouldValidate: true, shouldDirty: true });
      registration.onChange(event);
      onChange(event);
    }
  };

  useEffect(() => {
    if (isDefined(value)) {
      setValue(name, value, { shouldValidate: true, shouldDirty: false });
    }
  }, [value, name, setValue]);

  return (
    <>
      <tr>
        <td className="text-sm p-2 pl-4">{label}</td>
        <td className="p-2">
          <input
            id={name}
            {...registration}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            name={name}
            placeholder={defaultValue}
            className={`[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none input input-bordered input-sm w-full ${errorMessage ? 'input-error' : ''} ${!isDefault ? 'font-bold placeholder-shown:font-normal' : ''}`}
          />
        </td>
        <td className="p-2 pr-4 w-full">
          {convertibleUnits?.length > 0 && (
            <ConversionField
              className="w-full"
              unit={unit}
              name={`${parentName}.conversion.${name}`}
              paramName={name}
              units={convertibleUnits}
            />
          )}
        </td>
      </tr>
      {errorMessage && (
        <tr>
          <td></td>
          <td className="p-0 pl-1">
            <ErrorMessage text={errorMessage} className="p-0" />
          </td>
          <td></td>
        </tr>
      )}
    </>
  );
};

GroupInputField.propTypes = {
  paramSchema: PropTypes.object,
  param: PropTypes.object,
  index: PropTypes.number,
  name: PropTypes.string,
  parentName: PropTypes.string,
  onChange: PropTypes.func,
};

export default GroupInputField;
