import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

import GroupInputField from './GroupInputField';
import get from 'just-safe-get';
import ErrorMessage from './ErrorMessage';
import Tooltip from '@/components/core/Tooltip';
import usePatchParams from '../usePatchParams';

const GroupField = ({ paramSchema, param }) => {
  const {
    formState: { errors, dirtyFields },
    control,
    resetField,
    getValues,
  } = useFormContext();

  const { name, params: nestedParamSchemas, label, tooltip, source } = paramSchema;
  const error = get(errors, name);
  const { patchGroup } = usePatchParams();

  const onChange = async () => {
    const values = getValues(name);
    const filteredValues = Object.fromEntries(
      Object.entries(values)
        .map(([key, value]) => [key, { primary: { value } }])
        .filter(([key]) => dirtyFields[name]?.[key]),
    );

    try {
      await patchGroup(name, { [name]: { params: filteredValues } });
      resetField(name);
    } catch (error) {
      console.error(error);
    }
  };

  const errorMessage = error?.message?.params?.[0];
  const prefix = `${name}.`;

  const renderParams = () => {
    return nestedParamSchemas.map(nestedParamSchema => {
      const childName = `${prefix}${nestedParamSchema.name}`;
      const nestedParam = param?.params[nestedParamSchema.name];

      return (
        <GroupInputField
          key={childName}
          parentName={name}
          name={childName}
          onChange={onChange}
          paramSchema={nestedParamSchema}
          param={nestedParam}
        />
      );
    });
  };

  return (
    <>
      <div className="mb-2 pt-1 relative">
        <div className="flex items-center gap-1 self-center">
          {label && <div className="block truncate text-xs font-semibold">{label}</div>}
          {tooltip && <Tooltip label={label} text={tooltip} source={source} />}
        </div>
      </div>
      <div className="border rounded">
        <table className="">
          <thead className="text-xs border-b">
            <tr>
              <td className="p-2 pl-4 w-1/4">Name</td>
              <td className="p-2 w-1/2">Value</td>
              <td className="p-2 pr-4 w-1/4">Unit</td>
            </tr>
          </thead>
          <tbody>
            <Controller control={control} name={name} render={() => renderParams()} />
          </tbody>
        </table>
        <div className="flex justify-center">
          <ErrorMessage text={errorMessage} />
        </div>
      </div>
    </>
  );
};

GroupField.propTypes = {
  paramSchema: PropTypes.object,
  param: PropTypes.object,
};

export default GroupField;
