import PropTypes from 'prop-types';
import { ParamsContext } from './useParamsContext';

const ParamsProvider = ({ children, patch, fetchDerivedValues }) => {
  return <ParamsContext.Provider value={{ patch, fetchDerivedValues }}>{children}</ParamsContext.Provider>;
};

ParamsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  patch: PropTypes.func.isRequired,
  fetchDerivedValues: PropTypes.func,
};

export default ParamsProvider;
