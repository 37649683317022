import PropTypes from 'prop-types';
import FormRow from '@/components/core/form/FormRow';
import { useFormContext } from 'react-hook-form';
import get from 'just-safe-get';
import ErrorMessage from './ErrorMessage';
import ConversionField from '../ConversionField';
import MCFieldsWrapper from '@/components/nodes/MCFieldsWrapper';
import MCFields from '@/components/nodes/MCFields';
import usePatchParams from '../usePatchParams';
import { useEffect } from 'react';
import { isDefined } from '@/utils/miscUtils';

const InputField = ({ param, paramSchema, name }) => {
  const {
    label,
    type,
    tooltip,
    source,
    mc_eligible: isMcEligible,
    convertible_units: convertibleUnits,
    editable,
  } = paramSchema;
  const { primary: { value, is_default: isDefault, default: defaultValue } = {}, mc_min, mc_max, unit } = param ?? {};
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const error = get(errors, name);
  const errorMessage = error?.message?.primary?.value;
  const { patchPrimary } = usePatchParams();
  const registration = register(name, { valueAsNumber: type === 'numeric' });

  const events = {
    onChange: e => {
      e.preventDefault();
    },
    onBlur: async e => {
      const prevValue = getValues(name);
      const value = type === 'numeric' ? parseFloat(e.target.value) : e.target.value;

      if (prevValue !== value) {
        await patchPrimary(name, value);
      } else {
        setValue(name, value, { shouldValidate: true, shouldDirty: false });
      }
    },
  };

  useEffect(() => {
    if (isDefined(value)) {
      setValue(name, value, { shouldValidate: true, shouldDirty: false });
    }
  }, [value, name, setValue]);

  return (
    <>
      <FormRow label={label} tooltip={tooltip} source={source} name={name}>
        <div className="flex flex-col flex-1">
          <div className="join flex">
            <input
              id={name}
              {...registration}
              type="text"
              name={name}
              disabled={!editable}
              defaultValue={value}
              {...events}
              placeholder={defaultValue}
              className={`[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none input input-bordered input-sm w-full flex-1 join-item ${errorMessage ? 'input-error' : ''} ${!isDefault ? 'font-bold placeholder-shown:font-normal' : ''}`}
            />
            {convertibleUnits?.length > 0 && (
              <ConversionField
                unit={unit}
                name={`conversion.${name}`}
                paramName={name}
                units={convertibleUnits}
                disabled={!editable}
              />
            )}
          </div>
          <ErrorMessage text={errorMessage} />
        </div>
      </FormRow>
      {isMcEligible && (
        <MCFieldsWrapper mcMin={mc_min} mcMax={mc_max}>
          <MCFields name={name} mcMin={mc_min} mcMax={mc_max} />
        </MCFieldsWrapper>
      )}
    </>
  );
};

InputField.propTypes = {
  param: PropTypes.object,
  paramSchema: PropTypes.object,
  name: PropTypes.string,
  children: PropTypes.node,
};

export default InputField;
