import PropTypes from 'prop-types';
import { useForm, useWatch, FormProvider } from 'react-hook-form';
import FormRow from '@/components/core/form/FormRow';
import Select from '@/components/core/form/Select';

import debounce from 'debounce';
import { useNodeById, useNodes } from '@/stores/analysisStore';
import LevelizationForm from '@/components/core/LevelizationForm';

const WorkbenchPlotForm = ({ defaultValues, onSubmit }) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, control } = methods;
  const debouncedSave = debounce(() => handleSubmit(onSubmit)(), 10);

  const [nodeId, levelizeBy] = useWatch({
    control,
    name: ['nodeId', 'levelizeBy'],
    defaultValue: defaultValues,
  });

  const nodes = useNodes();
  const nodeOptions = nodes.map(({ id, label }) => ({ label, value: id }));
  const node = useNodeById(nodeId);

  return (
    <FormProvider {...methods}>
      <div className="group node">
        <form onChange={debouncedSave}>
          <div className="mt-2 grid grid-cols-2 gap-1">
            <FormRow label="Module" name="nodeId">
              <Select className="w-full truncate" name="nodeId" options={nodeOptions} />
            </FormRow>
            <LevelizationForm node={node} levelizeBy={levelizeBy} />
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

WorkbenchPlotForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default WorkbenchPlotForm;
