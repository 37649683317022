import { NavLink, useParams, useRouteLoaderData } from 'react-router-dom';
import EmptyState from '../pathways/EmptyState';

const ComparisonEmptyState = () => {
  const { projectId } = useParams();
  const {
    pathways: { results: pathways },
  } = useRouteLoaderData('project');

  let title = 'In order to create a comparison you will need to build, balance and analyze a pathway.';
  let url = `/projects/${projectId}/dashboard/`;
  let buttonLabel = 'View Dashboard';

  if (pathways.length) {
    title = 'Start by adding a comparison';
    url = `/projects/${projectId}/comparisons/new`;
    buttonLabel = ' Add Comparison';
  }

  return (
    <EmptyState title={title}>
      <NavLink to={url} className="btn btn-primary rounded-md leading-6 font-semibold flex">
        {buttonLabel}
      </NavLink>
    </EmptyState>
  );
};

export default ComparisonEmptyState;
