import PropTypes from 'prop-types';
import { getUnitOptions } from '@/utils/unitUtils';
import Select from '../core/form/Select';
import { useFormContext } from 'react-hook-form';
import usePatchParams from './usePatchParams';
import { useEffect } from 'react';
import { isDefined } from '@/utils/miscUtils';

const ConversionField = ({
  name,
  unit,
  paramName,
  units,
  className = 'join-item w-1/4 border-l-0',
  disabled = false,
}) => {
  const { value = '', is_default: isDefault } = unit ?? {};
  const options = getUnitOptions(units);

  const { register, setValue } = useFormContext();
  const { patchUnit } = usePatchParams();
  const unitValue = JSON.stringify(value);

  useEffect(() => {
    if (isDefined(unitValue)) {
      setValue(name, unitValue, { shouldValidate: true, shouldDirty: false });
    }
  }, [unitValue, name, setValue]);

  const onChange = async e => {
    const value = e.target.value;
    const unit = JSON.parse(value);

    await patchUnit(paramName, unit, name);
  };

  if (options.length === 1 || disabled) {
    return (
      <>
        <input {...register(name)} type="hidden" name={name} />
        <div className={`input input-sm ${disabled ? 'input-disabled' : 'input-bordered'}`}>{options[0].label}</div>
      </>
    );
  }

  return (
    <Select
      name={name}
      onChange={onChange}
      options={options}
      className={`select-sm w-fit ${className} ${!isDefault ? 'font-bold' : ''}`}
    />
  );
};

ConversionField.propTypes = {
  name: PropTypes.string,
  unit: PropTypes.object,
  units: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  paramName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ConversionField;
