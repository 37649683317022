import { isDefined } from './miscUtils';

// group params by top level categories
export const parseCategories = (params, level = 0) => {
  return params.reduce((acc, param) => {
    const categoryName = param?.category?.[level] ?? '';

    if (!acc[categoryName]) {
      acc[categoryName] = { categoryName, params: [] };
    }

    acc[categoryName].params.push(param);
    return acc;
  }, {});
};

export const getCategories = params => {
  const categorySet = params.reduce((acc, param) => {
    const categoryName = param?.category?.[0];

    if (categoryName) {
      acc.add(categoryName);
    }

    return acc;
  }, new Set());

  return Array.from(categorySet);
};

export const resolveParamName = (paramName, index, parentName) =>
  parentName ? `${parentName}${isDefined(index) ? `.${index}` : ''}.${paramName}` : paramName;
