import { SYSTEM_STATUS } from '@/consts';
import { useSystemStatus, systemActions } from '@/stores/systemStore';
import { PlayIcon } from '@heroicons/react/24/outline';

const RunSystemButton = () => {
  const status = useSystemStatus();

  if (status === SYSTEM_STATUS.running) {
    return (
      <button className="btn btn-sm btn-secondary">
        <span className="loading loading-spinner"></span>
      </button>
    );
  }

  return (
    <a onClick={systemActions.run} className="btn btn-sm btn-primary">
      <PlayIcon className="w-5 h-5" />
      Run
    </a>
  );
};

export default RunSystemButton;
