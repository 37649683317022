import axios from 'axios';

const { get, post, patch } = axios;

export const getPathway = pathwayId => get(`/v1/pathways/${pathwayId}/`);
export const getPathways = (projectId, params = {}) => get(`/v1/projects/${projectId}/pathways/`, { params });
export const disconnectInputFromOutput = (pathwayId, data) => post(`/v1/pathways/${pathwayId}/disconnect/`, data);
export const connectInputToOutput = (pathwayId, data) => post(`/v1/pathways/${pathwayId}/connect/`, data);
export const createPathway = (projectId, data) => post(`/v1/projects/${projectId}/pathways/`, data);
export const patchPathway = (pathwayId, data) => patch(`/v1/pathways/${pathwayId}/`, data);
export const deletePathway = pathwayId => axios.delete(`/v1/pathways/${pathwayId}/`);
export const lockPathway = pathwayId => axios.post(`/v1/pathways/${pathwayId}/lock/`);
export const unlockPathway = pathwayId => axios.post(`/v1/pathways/${pathwayId}/unlock/`);

export const getPathwayParamInfo = () => get(`/v1/pathways/params/`);
export const getPathwayParams = pathwayId => get(`/v1/pathways/${pathwayId}/params/`);
export const patchPathwayParams = (pathwayId, data) => patch(`/v1/pathways/${pathwayId}/params/`, data);
