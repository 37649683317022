import { registryActions, useActiveNodeTypes, useCategories, useActiveCategory } from '@/stores/registryStore';
import RegistryForm from './RegistryForm';
import { ArrowsPointingInIcon } from '@heroicons/react/24/outline';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useRef } from 'react';
import { Rnd } from 'react-rnd';
import Walkthrough from '../core/Walkthrough';
import ScrollableList from '../core/ScrollableList';
import { settingsActions, useIsPaletteOpen, usePaletteHeight } from '@/stores/settingsStore';

const defaultValues = {};
const CategoriesList = () => {
  const categories = useCategories();
  const activeCategory = useActiveCategory();
  const categoriesListRef = useRef(null);

  const handleScroll = useCallback(direction => {
    const list = categoriesListRef.current;
    if (list) {
      const scrollAmount = direction === 'right' ? 100 : -100;
      list.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  }, []);

  return (
    <ScrollableList onScrollLeft={() => handleScroll('left')} onScrollRight={() => handleScroll('right')}>
      <ul
        className="flex min-w-0 w-full overflow-x-auto scrollbar-none gap-1 module-categories"
        ref={categoriesListRef}
      >
        {categories.map(category => (
          <li key={category}>
            <button
              onClick={() => registryActions.setActiveCategory(category)}
              className="transition hover:bg-orange-50 rounded-full text-xs inline-flex items-center p-2 text-left w-full truncate"
            >
              <div
                className={`mr-2 w-2 h-2 rounded border shrink-0 ${activeCategory === category ? 'font-bold rounded bg-orange-400 border-orange-400' : 'border-orange-400'}`}
              />
              {category}
            </button>
          </li>
        ))}
      </ul>
    </ScrollableList>
  );
};

const PathwayRegistry = () => {
  const activeNodeTypes = useActiveNodeTypes();
  const toolbar = useRef(null);
  const collapsedBlock = useRef(null);
  const { height } = collapsedBlock?.current?.getBoundingClientRect() || { height: 48 };
  const activeCategory = useActiveCategory();
  const paletteHeight = usePaletteHeight(activeCategory);

  const isPaletteOpen = useIsPaletteOpen();

  const onDragStart = (event, nodeType) => {
    const nodeTypeStr = JSON.stringify(nodeType);
    event.dataTransfer.setData('nodeType', nodeTypeStr);
    event.dataTransfer.effectAllowed = 'move';
  };

  const close = e => {
    if (e.target.closest('#closeBtn')) {
      settingsActions.closePalette();
    }
  };
  const open = e => {
    if (!e.target.closest('#closeBtn')) {
      toolbar.current.updateSize({ height: paletteHeight });
      settingsActions.openPalette();
    }
  };

  const onSubmit = useCallback(data => {
    registryActions.updateSearch(data);
  }, []);

  useEffect(() => {
    if (!isPaletteOpen) {
      toolbar.current.updateSize({ height: `${height}px` });
    } else {
      toolbar.current.updateSize({ height: paletteHeight });
    }
  }, [isPaletteOpen, paletteHeight, height]);

  useEffect(() => {
    registryActions.loadSystems();

    return () => {
      registryActions.clear();
    };
  }, []);

  return (
    <Rnd
      bounds="parent"
      minWidth="calc(100% - 16px)"
      minHeight={height}
      enableUserSelectHack={false}
      className="z-[1] m-2"
      ref={c => (toolbar.current = c)}
      disableDragging
      onClick={open}
      onResizeStop={(e, direction, ref) => {
        settingsActions.setPaletteHeight(activeCategory, ref.offsetHeight);
      }}
    >
      <div
        className={`group rounded overflow-hidden select-none bg-base-100 h-full module-palette drop-shadow-lg shadow-slate-500 ${!isPaletteOpen ? 'collapsed' : ''}`}
      >
        <div className="flex flex-col h-full border-b-1 border-gray-100">
          <div className="flex group-[.collapsed] border-b border-gray-100" ref={collapsedBlock}>
            <div className="grid grid-cols-[auto_1fr_auto] h-12 items-center gap-2 min-w-0 w-full pl-4">
              <div className="text-lg font-semibold">Modules</div>
              <CategoriesList />
              <RegistryForm defaultValues={defaultValues} onSubmit={onSubmit} />
            </div>
            <div className="flex module-palette-control justify-end">
              <button onClick={open} className="hidden group-[.collapsed]:block">
                <ArrowsPointingOutIcon className="transition h-8 m-2 border border-gray-200 rounded p-1 text-gray-400 hover:text-gray-300" />
              </button>
              <button onClick={close} id="closeBtn" className="block group-[.collapsed]:hidden">
                <ArrowsPointingInIcon className="transition h-8 m-2 border border-gray-200 rounded p-1 text-gray-400 hover:text-gray-300" />
              </button>
            </div>
          </div>
          <div className="flex-1 overflow-y-scroll scrollbar-thin p-2 module-selection">
            {activeNodeTypes.map(({ disabled, ...nodeType }, index) => (
              <button
                draggable={!disabled}
                key={`${nodeType.name}-${index}`}
                onMouseDown={event => event.stopPropagation()}
                onDragStart={event => onDragStart(event, nodeType)}
                style={{ cursor: disabled ? 'default' : 'grab' }}
                className={`text-xs transition ${disabled ? 'bg-neutral-50 text-gray-400' : 'border-slate-100 border hover:bg-orange-50 hover:border-orange-400'} rounded p-2 m-1`}
              >
                {nodeType.label}
              </button>
            ))}
          </div>
        </div>
      </div>

      <Walkthrough type="modules" />
    </Rnd>
  );
};

export default PathwayRegistry;
