import filterDatasets from '@/utils/filterDatasets';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

const initialValues = {
  datasets: {},
  searchParams: {},
  selectedFilterTags: {},
  submittedFilterTags: null,
  selectedFilterLogic: 'OR',
  savedDatasetList: {},
};

const useDataRegistryStore = create()(immer(() => initialValues));

const { setState: set } = useDataRegistryStore;

export const registryActions = {
  init: registry => {
    const datasets = registry.metadata.sort((a, b) => a.datatable_name.localeCompare(b.datatable_name));
    const tags = registry.tags;
    const pointers = registry.pointers;

    datasets.forEach(table => {
      table.tags = []; // Initialize empty array for tags
    });

    datasets.forEach(dataset => {
      // Find matching tags
      const matchingTagIds = pointers
        .filter(tagAssignment => tagAssignment.metadata_pointer === dataset.datatable_name)
        .map(tag => tag.tag_pointer);
      const tagsForData = tags.filter(tag => matchingTagIds.includes(tag.id)).map(tag => tag.tag);
      // Add matching tags to the 'tags' array of the current table
      dataset.tags.push(...tagsForData);
    });

    const totalDatasets = datasets.length;

    //INSTEAD OF HERE, CALCULATE tagsByCategory IN SPECIFIC COMPONENTS THAT NEED IT
    const uniqueTagCategoriesArr = [...new Set(tags.map(item => item.tag_category))];
    const tagsByCategory = uniqueTagCategoriesArr.reduce((acc, category) => {
      // For each unique category, filter tags that belong to that category
      acc[category] = tags.filter(item => item.tag_category === category).map(item => item.tag);

      return acc;
    }, {});

    const selectedFilterTags = [];
    const savedDatasetList = [];
    set({ datasets, totalDatasets, tagsByCategory, selectedFilterTags, savedDatasetList });
  },
  updateSearch: searchParams => set({ searchParams }),
  setFilterLogicToggle: selectedFilterLogic => {
    set({ selectedFilterLogic });
  },

  updateFilter: selectedFilterTags => {
    set({ selectedFilterTags });
  },
  setFilter: submittedFilterTags => {
    set({ submittedFilterTags });
  },
  clear: () => set({ searchParams: {} }),
  updateSavedDatasets: savedDatasetList => {
    set({ savedDatasetList });
  },
};

export const useActiveDatasets = () =>
  useDataRegistryStore(store => {
    const { submittedFilterTags, searchParams, selectedFilterLogic, datasets } = store;
    const { term } = searchParams;
    let activeDatasets = filterDatasets(term, submittedFilterTags, selectedFilterLogic, datasets);
    return activeDatasets;
  });
export const useTagCategories = () => useDataRegistryStore(store => store.tagsByCategory);
export const useSelectedFilterTags = () => useDataRegistryStore(store => store.selectedFilterTags);
export const useSubmittedFilterTags = () => useDataRegistryStore(store => store.submittedFilterTags);
export const useSavedDatasetList = () => useDataRegistryStore(store => store.savedDatasetList);

export default useDataRegistryStore;
