import ProjectList from '@/components/projects/ProjectList';
import { Link, Outlet } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';
import PageTitle from '@/components/core/PageTitle';
import Changelog from '@/components/changelog/Changelog';

const NewProjectLink = () => (
  <Link to="/projects/new" className="btn btn-primary btn-sm">
    <PlusIcon className="h-5 w-5 fill-gray-100" />
    New Project
  </Link>
);

const ProjectsRoute = () => (
  <div className="flex flex-col bg-white shadow-lg shadow-slate-200 rounded-t-lg h-full size-11/12 overflow-hidden">
    <PageTitle className="bg-slate-600 text-white" />
    <div className="overflow-y-scroll">
      <div className="flex flex-grow">
        <div className="w-[60%] p-4">
          <div className="flex justify-between font-bold text-lg ml-3 mb-2 border-b p-4">
            <div className="font-bold text-lg">Projects</div>
            <NewProjectLink />
          </div>
          <ProjectList />
        </div>
        <div className="w-[40%] p-4">
          <Changelog />
          <div className="rounded-md bg-gray-100 p-8 mt-8">
            <div className="font-bold text-lg mb-2">Helpful How-Tos</div>
            <ul>
              <li className="text-sm mt-4">
                <Link
                  to="https://docs.sesame.software/essentials/getting-started.html#creating-a-pathway"
                  target="_blank"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Creating a Pathway
                </Link>
              </li>
              <li className="text-sm mt-4">
                <Link
                  to="https://docs.sesame.software/essentials/getting-started.html#changing-pathway-parameters"
                  target="_blank"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Changing Pathway Parameters
                </Link>
              </li>
              <li className="text-sm mt-4">
                <Link
                  to="https://docs.sesame.software/essentials/getting-started.html#running-a-pathway"
                  target="_blank"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Running a Pathway
                </Link>
              </li>
              <li className="text-sm mt-4">
                <Link
                  to="https://docs.sesame.software/essentials/getting-started.html#visualizing-results"
                  target="_blank"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Visualizing Results
                </Link>
              </li>
              <li className="text-sm mt-4">
                <Link
                  to="https://docs.sesame.software/essentials/getting-started.html#comparison-of-multiple-pathways"
                  target="_blank"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Comparison of Multiple Pathways
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  </div>
);

export default ProjectsRoute;
