import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import MCFieldsSummary from './MCFieldsSummary';

const MCFieldsWrapper = ({ children, mcMin, mcMax }) => {
  const { control } = useFormContext();
  const isMcEnabled = useWatch({
    control,
    name: 'mc_params._enabled',
  });

  return (
    <>
      {!isMcEnabled && <MCFieldsSummary min={mcMin?.value} max={mcMax?.value} />}
      <div className={`flex gap-4 ${isMcEnabled ? 'visible' : 'hidden'}`}>{children}</div>
    </>
  );
};

MCFieldsWrapper.propTypes = {
  children: PropTypes.node,
  mcMin: PropTypes.object,
  mcMax: PropTypes.object,
};

export default MCFieldsWrapper;
