import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { caseActions } from './caseStore';

const initialValues = null;
const useNodeStore = create()(immer(() => initialValues));
const { setState: set } = useNodeStore;

// actions
export const nodeActions = {
  init: (node, caseNode, params) => {
    set({ node, caseNode, params });
  },

  clear: () => set(initialValues, true),

  updateParams: async params => {
    set({ params });
  },

  // TODO: remove this
  patchParams: async () => {
    caseActions.markUnbalanced();
  },
};

// selectors
export const useNodeParams = () => useNodeStore(store => store?.node?.params);
export const useCaseNodeParams = () => useNodeStore(store => store?.params);

export default useNodeStore;
