import { Outlet } from 'react-router-dom';
import SystemInputs from '@/components/systems/SystemInputs';
import { useSystemId } from '@/stores/systemStore';

const SystemRoute = () => {
  const systemId = useSystemId();
  return (
    <div className="min-h-0 flex flex-col px-4 pb-4" key={systemId}>
      <SystemInputs />
      <Outlet />
    </div>
  );
};

export default SystemRoute;
