import PropTypes from 'prop-types';
import FormRow from '@/components/core/form/FormRow';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { isDefined } from '@/utils/miscUtils';
import { useDerivedParam } from '@/stores/derivedCacheStore';
import { useDerivedValueFetch } from '@/hooks/useDerivedValueFetch';
import ConversionField from '../ConversionField';

const DerivedField = ({ param, paramSchema, name }) => {
  const { label, type, tooltip, source, convertible_units: convertibleUnits } = paramSchema;
  const { primary: { value } = {} } = param ?? {};
  const { fetch, changed_dependencies: changedDependencies } = value ?? {};
  const { register, setValue } = useFormContext();
  const registration = register(name, { valueAsNumber: type === 'numeric' });
  const results = useDerivedParam(name);
  const shouldFetch = fetch || !results;

  const { isLoading } = useDerivedValueFetch({
    name,
    shouldFetch,
    changedDependencies,
  });

  useEffect(() => {
    if (isDefined(results?.scalar)) {
      setValue(name, results.scalar, { shouldValidate: true, shouldDirty: false });
    }
  }, [results, name, setValue]);

  if (!results && !isLoading) {
    return null;
  }

  return (
    <FormRow label={label} tooltip={tooltip} source={source} name={name}>
      <div className="flex flex-col flex-1">
        <div className="join flex">
          <input
            id={name}
            {...registration}
            type="text"
            name={name}
            value={results?.scalar ?? ''}
            disabled
            className={`[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none input input-bordered input-sm w-full flex-1 join-item}`}
          />
          {convertibleUnits?.length > 0 && (
            <ConversionField
              unit={{ value: results?.unit }}
              name={`conversion.${name}`}
              paramName={name}
              units={convertibleUnits}
              disabled
            />
          )}
        </div>
      </div>
    </FormRow>
  );
};

DerivedField.propTypes = {
  param: PropTypes.object,
  paramSchema: PropTypes.object,
  name: PropTypes.string,
  children: PropTypes.node,
};

export default DerivedField;
