import { useEffect } from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { menuAction } from '@/stores/menuStore';
import { useLocation } from 'react-router-dom';
import EmptyState from '@/components/pathways/EmptyState';

const BuildRoute = () => {
  useEffect(() => {
    menuAction.open();
  }, []);

  const { projectId } = useParams();
  const { pathname } = useLocation();
  const displayEmptyState = pathname.match(/build$/);

  return (
    <div className="w-full flex flex-col flex-1 bg-canvas min-h-0">
      {displayEmptyState ? (
        <EmptyState title="Start by building a pathway or system.">
          <>
            <NavLink
              to={`/projects/${projectId}/build/pathways/new`}
              className="btn btn-primary rounded-md leading-6 font-semibold flex"
            >
              Build Pathway
            </NavLink>
            <NavLink
              to={`/projects/${projectId}/build/systems/new`}
              className="btn btn-secondary rounded-md leading-6 font-semibold flex"
            >
              Build System
            </NavLink>
          </>
        </EmptyState>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default BuildRoute;
