import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import PanelHeader from '../core/PanelHeader';
import NodeFields from './NodeFields';
import { useIsSavePending } from '@/stores/paramStore';

const NodeForm = ({ caseParams, categories, actions, title, description, className = '' }) => {
  const methods = useForm({ mode: 'onChange' });
  const isPending = useIsSavePending();
  const { handleSubmit, reset } = methods;

  const onInnerSubmit = async data => {
    reset(data);
  };

  const checkKeyDown = e => {
    if (e.key === 'Enter') {
      if (document.activeElement.tagName !== 'body') {
        document.activeElement.blur();
      }

      setTimeout(() => {
        handleSubmit(onInnerSubmit)(e);
      }, 100);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onKeyDown={checkKeyDown} onSubmit={handleSubmit(onInnerSubmit)} noValidate className="h-full flex flex-col">
        <PanelHeader title={title} isEditable>
          {actions}
          {isPending && <span className="text-xs mr-2 text-gray-500">Saving...</span>}
          {isPending === false && <span className="text-xs mr-2 text-gray-500">Saved</span>}
        </PanelHeader>
        <NodeFields caseParams={caseParams} description={description} categories={categories} className={className} />
      </form>
    </FormProvider>
  );
};

NodeForm.propTypes = {
  caseParams: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  actions: PropTypes.node,
  className: PropTypes.string,
  categories: PropTypes.object,
};

export default NodeForm;
