import { FormProvider, useForm, useWatch } from 'react-hook-form';
import FormRow from '@/components/core/form/FormRow';
import Select from '@/components/core/form/Select';
import { unitToString } from '@/utils/unitUtils';
import CashFlowBarChart from '@/components/charts/CashFlowBarChart';
import PropTypes from 'prop-types';

const DEFAULT_PLOT = 'After Tax Post Depreciation Cash Flow';

const CashFlowChartContainer = ({ data, label }) => {
  const plottable = data?.filter(result => result.plottable);
  const yearLabels = data?.find(result => result.category[1] === 'Year');
  const defaultValues = { plotColumn: DEFAULT_PLOT };
  const methods = useForm({ defaultValues, mode: 'onChange' });
  const { control } = methods;

  const [plotColumn] = useWatch({
    control,
    name: ['plotColumn'],
    defaultValue: defaultValues,
  });

  const currentPlot = plottable?.find(p => p.category.includes(plotColumn)) || plottable?.[0];

  const category = currentPlot?.category[1];
  const unit = unitToString(currentPlot?.unit);
  const options = plottable?.map(result => ({ value: result.category[1], label: result.category[1] }));

  if (!plottable) {
    return null;
  }

  const yAxisLabel = `${label ? `${label} - ` : ''}${category}\n${unit}/year`;

  return (
    <>
      <div className="h-96">
        <CashFlowBarChart data={currentPlot.data} labels={yearLabels.data} yAxisLabel={yAxisLabel} />
      </div>
      <div>
        <FormProvider {...methods}>
          <div className="group node">
            <form>
              <div className="mt-2 grid grid-cols-4 gap-2">
                <FormRow label="Change Graph" name="plotColumn">
                  <Select name="plotColumn" className="w-full truncate" options={options} />
                </FormRow>
              </div>
            </form>
          </div>
        </FormProvider>
      </div>
    </>
  );
};

CashFlowChartContainer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plottable: PropTypes.bool,
      category: PropTypes.arrayOf(PropTypes.string),
      unit: PropTypes.object,
    }),
  ),
  label: PropTypes.string,
};

export default CashFlowChartContainer;
