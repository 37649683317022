import { caseActions, useFlowRate } from '@/stores/caseStore';
import FormRow from '../core/form/FormRow';
import Select from '../core/form/Select';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { FLOW_RATE_TYPES } from '@/consts';

const FlowRateSelector = () => {
  const flowRate = useFlowRate();
  const defaultValues = { flowRate };
  const methods = useForm({ defaultValues });
  const changeFlow = e => caseActions.changeFlowRate(e.target.value);
  const { reset } = methods;

  useEffect(() => {
    reset({ flowRate });
  }, [flowRate, reset]);

  return (
    <FormProvider {...methods}>
      <form>
        <FormRow label="Flow Rate" name="flowRate">
          <Select
            onChange={changeFlow}
            name="flowRate"
            className="w-full select-sm"
            registerOptions={{ valueAsNumber: true }}
            options={FLOW_RATE_TYPES}
          />
        </FormRow>
      </form>
    </FormProvider>
  );
};

export default FlowRateSelector;
