import { getSensitivitySweep, getSensitivitySweepResults, startSensitivitySweep } from '@/api/analyses';
import { getNodeWithInfo } from '@/api/nodes';
import { CHANGE_DELAY_AFTER, CHECK_FIRST_DELAY, CHECK_SECOND_DELAY, SENSITIVITY_STATUS } from '@/consts';
import { isEmpty, isNumber, keyBy, runInterval } from '@/utils/miscUtils';
import { getCategories } from '@/utils/paramsUtils';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { subscribeWithSelector } from 'zustand/middleware';
import { getIOOptions, getUnitOptions, getUnitsByIO } from '@/utils/pathwayUtils';
import { nanoid } from 'nanoid';
import { getParamLabels, getWorkbenchUnits, parseResults } from '@/utils/workbenchUtils';

import { enableMapSet } from 'immer';

enableMapSet();

const initParam = {
  baseline: null,
  min: null,
  max: null,
  unit: null,
};

const initFormData = {
  nodeId: null,
  category: null,
  min: null,
  baseline: null,
  max: null,
};

const initialValues = {
  workbenches: new Map(),
  pathway: null,
  caseData: null,
  analysis: null,
  results: null,
  error: null,
  status: null,
  paramsByNodeId: {},
  paramCategories: {},
  nodesById: {},
  lca: {},
  tea: {},
  loading: false,
};

const useWorkbenchStore = create()(subscribeWithSelector(immer(() => initialValues)));
const { setState: set, getState: get } = useWorkbenchStore;

// actions
export const workbenchActions = {
  init: async (pathway, caseData, analysis) => {
    workbenchActions.clear();

    set({ pathway, caseData, analysis });

    workbenchActions.checkStatusPeriodically();
  },

  add: () => {
    const key = nanoid();
    const formData = {
      key,
      ...initFormData,
    };

    set(state => {
      state.workbenches.set(key, formData);
    });
  },

  remove: key => {
    set(state => {
      state.workbenches.delete(key);
    });
  },

  checkStatus: async () => {
    const { analysis } = get();

    if (!analysis.id) {
      return false;
    }

    try {
      const {
        data: { id, status, config },
      } = await getSensitivitySweep(analysis.id);

      if (status === SENSITIVITY_STATUS.succeeded || status === SENSITIVITY_STATUS.failed) {
        await workbenchActions.updateFromServer(config);
      }

      set({ status, sweepId: id, loading: true });

      if (status === SENSITIVITY_STATUS.failed) {
        set({ error: 'sensitivity workbench failed', loading: false });
      }

      if (status === SENSITIVITY_STATUS.succeeded || status === SENSITIVITY_STATUS.failed) {
        return true;
      }
    } catch (err) {
      set({ sweepId: null, status: SENSITIVITY_STATUS.failed, loading: false });
      workbenchActions.add();
      return true;
    }

    return false;
  },

  checkStatusPeriodically: () => {
    runInterval(workbenchActions.checkStatus, CHECK_FIRST_DELAY, CHECK_SECOND_DELAY, CHANGE_DELAY_AFTER);
  },

  updateConfig: async (config, index) => {
    const { node_id: nodeId, param_name: paramName, min, max, steps } = config;
    const { workbenches } = get();
    const workbenchValues = Array.from(workbenches.values());
    const key = workbenchValues?.[index]?.key || nanoid();

    await workbenchActions.updateNodeInfo(nodeId);
    const param = await workbenchActions.updateParamInfo({ key, nodeId, paramName });
    const formData = {
      key,
      nodeId,
      paramName,
      min,
      max,
      steps,
      baseline: param?.baseline,
      unit: param?.unit,
    };

    const { paramsByNodeId } = get();

    formData.category = paramsByNodeId?.[nodeId]?.[paramName]?.category?.[0];

    set(state => {
      state.workbenches.set(key, formData);
    });
  },

  // update form data with data from server
  updateFromServer: async configs => {
    await Promise.all(
      configs.map(async (config, index) => {
        await workbenchActions.updateConfig(config, index);
      }),
    );
  },

  updateNodeInfo: async nodeId => {
    const { paramsByNodeId, pathway, caseData } = get();

    if (nodeId && !paramsByNodeId[nodeId]) {
      const { node, params: nodeParams } = await getNodeWithInfo(pathway.id, caseData.id, nodeId);
      const filteredParams = node.params.filter(param => nodeParams[param.name]);

      set(state => {
        state.paramsByNodeId[nodeId] = keyBy(filteredParams, 'name');
        state.paramCategories[nodeId] = getCategories(filteredParams);
        state.nodesById[nodeId] = { ...node, nodeParams };
      });
    }
  },

  updateParamInfo: async formData => {
    const { nodeId, paramName } = formData;

    if (!paramName) {
      return initParam;
    }

    const { nodesById } = get();
    const node = nodesById[nodeId];
    const nodeParam = node.nodeParams[paramName];

    const workbenchParam = {
      baseline: nodeParam?.primary?.value,
      min: nodeParam?.mc_min?.value,
      max: nodeParam?.mc_max?.value,
      unit: nodeParam?.unit?.value,
    };

    return workbenchParam;
  },

  // update form data when form is changed
  update: async formData => {
    const { workbenches } = get();
    const { key, nodeId } = formData;
    const workbench = workbenches.get(key);

    await workbenchActions.updateNodeInfo(nodeId);
    const param = await workbenchActions.updateParamInfo(formData);
    const data = workbench.paramName !== formData.paramName ? { ...formData, ...param } : formData;

    data.unit = param.unit;

    set(state => {
      state.workbenches.set(key, data);
    });
  },

  updatePlot: async (type, formData) => {
    set({ loading: true });
    try {
      await workbenchActions.getResultsByType(type, formData);
    } finally {
      set({ loading: false });
    }
  },

  run: async () => {
    const { workbenches, analysis } = get();
    set({ error: '', lca: {}, tea: {}, loading: true });

    const requestData = Array.from(workbenches.values()).map(workbench => {
      const { nodeId, paramName, min, max, unit, steps } = workbench;
      return {
        node_id: nodeId,
        param_name: paramName,
        min,
        max,
        steps,
        unit,
      };
    });

    try {
      set({ status: SENSITIVITY_STATUS.pending });
      await startSensitivitySweep(analysis.id, requestData);
      workbenchActions.checkStatusPeriodically();
    } catch (err) {
      const data = err?.response?.data;
      let error = '';

      if (data) {
        for (const key in data) {
          error += `${key} ${data[key][0]} `;
        }
      }

      set({ error, status: SENSITIVITY_STATUS.failed });
    }
  },

  getResults: async () => {
    set({ loading: true });

    try {
      await Promise.all([workbenchActions.getResultsByType('lca'), workbenchActions.getResultsByType('tea')]);
    } finally {
      set({ loading: false });
    }
  },

  getResultsByType: async (type, plotFormData = {}) => {
    const {
      sweepId,
      workbenches,
      pathway: { nodes },
      paramsByNodeId,
    } = get();
    let { levelizeBy, levelizeUnit } = plotFormData;
    const nodesById = keyBy(nodes, 'id');
    const nodeId = plotFormData.nodeId || nodes[0].id;
    const node = nodesById[nodeId];

    if (!levelizeBy) {
      const unitsByIO = getUnitsByIO(node);
      const levelizeByOptions = getIOOptions(node);
      levelizeBy = levelizeByOptions?.[0]?.value;
      const levelizeUnitOptions = getUnitOptions(unitsByIO?.[levelizeBy]?.units);
      levelizeUnit = levelizeUnitOptions?.[0].value;
    }

    const { io_name, io_type } = JSON.parse(levelizeBy);
    const params = {
      analysis_type: type,
      node_id: nodeId,
      levelization: {
        io_name,
        io_type,
        unit: JSON.parse(levelizeUnit),
      },
    };

    const { data } = await getSensitivitySweepResults(sweepId, params);

    if (!data.series) {
      return;
    }

    const results = parseResults(data);
    const paramLabels = getParamLabels(workbenches, paramsByNodeId);
    const workbenchUnits = getWorkbenchUnits(workbenches);

    set(state => {
      state[type] = {
        results,
        node: nodesById[nodeId],
        nodeId: nodeId,
        paramLabels,
        workbenchUnits,
        levelizeBy,
        levelizeUnit,
      };
    });
  },
  clear: () => set(initialValues, true),
};

// selectors
export const useWorkbenchError = () => useWorkbenchStore(store => store.error);
export const useParamCategories = nodeId => useWorkbenchStore(store => store.paramCategories?.[nodeId] ?? []);
export const useNodeParams = (nodeId, category) =>
  useWorkbenchStore(store => {
    if (!nodeId || !category) {
      return [];
    }

    const params = Object.values(store.paramsByNodeId?.[nodeId] ?? {});

    return params.filter(param => param.category.includes(category) && param.mc_eligible);
  });

export const useSweepId = () => useWorkbenchStore(store => store.sweepId);

export const useNodeParamsOptions = (nodeId, category) => {
  const params = useNodeParams(nodeId, category);
  const options = params.map(param => ({ value: param.name, label: param.label }));

  return [{ value: '', label: '' }, ...options];
};

export const useWorkbench = key => useWorkbenchStore(store => store.workbenches.get(key));
export const useLCAPlot = () => useWorkbenchStore(store => store.lca);
export const useTEAPlot = () => useWorkbenchStore(store => store.tea);
export const useIsLoading = () => useWorkbenchStore(store => store.loading);
export const useWorkbenches = () => useWorkbenchStore(store => Array.from(store.workbenches.values()));
export const useIsValid = () =>
  useWorkbenchStore(store => {
    const { workbenches } = store;

    if (isEmpty(workbenches)) {
      return false;
    }

    const workbench = Array.from(workbenches.values()).find(workbench => {
      const { nodeId, category, paramName, min, max } = workbench;
      return !nodeId || !category || !paramName || !isNumber(min) || !isNumber(max);
    });

    return workbench ? false : true;
  });

useWorkbenchStore.subscribe(
  state => state.status,
  (status, previousStatus) => {
    if (status === SENSITIVITY_STATUS.succeeded && previousStatus !== SENSITIVITY_STATUS.succeeded) {
      workbenchActions.getResults();
    }
  },
);

export default useWorkbenchStore;
