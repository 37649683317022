import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useRouteError, useNavigate } from 'react-router-dom';
import { authActions } from '@/stores/authStore';

const getErrorMessage = error => {
  if (error.message === 'Network Error') {
    return 'Unable to connect to the server. Please check your internet connection.';
  }

  const status = error?.response?.status;

  switch (status) {
    case 403:
      return 'You do not have permission to perform this action.';
    case 404:
      return 'Resource not found.';
    default:
      return 'Oops! Something went wrong.';
  }
};

const useHandleAuthError = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  const status = error?.response?.status;

  if (status !== 401) {
    Sentry.captureException(error);
  }

  useEffect(() => {
    if (status === 401) {
      authActions.logout();
      navigate('/login');
    }
  }, [status, navigate]);

  return getErrorMessage(error);
};

export default useHandleAuthError;
