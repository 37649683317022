import PropTypes from 'prop-types';

import InputField from './fields/InputField';
import SelectField from './fields/SelectField';
import ToggleField from './fields/ToggleField';
import BlockField from './fields/BlockField';
import { parseCategories } from '@/utils/paramsUtils';
import ParameterTabsView from './ParameterTabsView';
import ParameterGridView from './ParameterGridView';
import { VIEW_TYPES } from '@/consts';
import LocationField from './fields/LocationField';

const paramTypes = {
  numeric: InputField,
  string: InputField,
  options: SelectField,
  boolean: ToggleField,
  block: BlockField,
  location: LocationField,
};

const ParameterFields = ({
  paramSchemas,
  paramValues,
  categories,
  className = '',
  parentName,
  index,
  viewType = VIEW_TYPES.grid,
}) => {
  const prefix = parentName ? `${parentName}.${index}.` : '';
  const renderParamsBySubCategory = params => {
    const subCategories = parseCategories(params, 1);

    return Object.values(subCategories).map((category, index) => {
      const { categoryName, params } = category;
      const showCategory = params.some(({ name: paramName }) => paramValues[paramName]);

      if (!showCategory) {
        return renderParams(params);
      }

      return (
        <div key={`subcategory-${index}`} className="flex flex-col min-h-0">
          {categoryName ? (
            <>
              <p className="border-b font-medium text-xs pb-2 ml-4 mr-4 mb-4">{categoryName}</p>
              {renderParams(params)}
            </>
          ) : (
            renderParams(params)
          )}
        </div>
      );
    });
  };

  const renderParams = params => {
    return params
      .filter(paramSchema => paramTypes[paramSchema.type])
      .map(paramSchema => {
        const { name, type } = paramSchema;
        const fullName = `${prefix}${name}`;
        const FieldComponent = paramTypes[type];
        const param = paramValues[name];
        const tabsClasses = viewType === VIEW_TYPES.tabs ? 'group node' : '';
        const classes = type !== 'block' ? `pb-2 mt-2 mb-2 ${className} ${tabsClasses}` : 'flex flex-col min-h-0';

        return (
          param && (
            <div key={fullName} className={classes}>
              <FieldComponent name={fullName} paramSchema={paramSchema} param={param} prefix={prefix} />
            </div>
          )
        );
      });
  };

  if (!categories) {
    return renderParamsBySubCategory(paramSchemas);
  }

  return (
    <>
      {viewType === VIEW_TYPES.grid && (
        <ParameterGridView
          categories={categories}
          paramValues={paramValues}
          renderParamsBySubCategory={renderParamsBySubCategory}
        />
      )}
      {viewType === VIEW_TYPES.tabs && (
        <ParameterTabsView
          categories={categories}
          paramValues={paramValues}
          renderParamsBySubCategory={renderParamsBySubCategory}
        />
      )}
    </>
  );
};

ParameterFields.propTypes = {
  paramSchemas: PropTypes.arrayOf(PropTypes.object),
  paramValues: PropTypes.object,
  categories: PropTypes.object,
  className: PropTypes.string,
  parentName: PropTypes.string,
  index: PropTypes.number,
  field: PropTypes.object,
  viewType: PropTypes.string,
};

export default ParameterFields;
