import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import FormRow from '@/components/core/form/FormRow';
import debounce from 'debounce';
import Select from '@/components/core/form/Select';
import { FormProvider } from 'react-hook-form';
import { useSavedComparisonCases } from '@/stores/comparisonStore';

const unitOptions = [
  { value: 'ton', label: '$/ton' },
  { value: 'kg', label: '$/kg' },
];

const COACComparisonForm = ({ defaultValues, onSubmit }) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit } = methods;
  const debouncedSave = debounce(() => handleSubmit(onSubmit)(), 10);
  const comparisonCases = useSavedComparisonCases();
  const comparisonCaseOptions = comparisonCases.map(({ id, name }) => ({ value: id, label: name }));

  return (
    <div className="group node">
      <FormProvider {...methods}>
        <form onChange={debouncedSave}>
          <div className="mt-2 grid grid-cols-5 gap-2">
            <FormRow label="Baseline" name="Baseline">
              <Select className="w-full truncate" name="baseline" options={comparisonCaseOptions} />
            </FormRow>
            <FormRow label="Unit" name="Unit">
              <Select className="w-full truncate" name="unit" options={unitOptions} />
            </FormRow>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

COACComparisonForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default COACComparisonForm;
